import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment/moment";

const columns = [
  { id: "name", label: "Username" },
  {
    id: "conversation_count",
    label: "No of Sessions",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "message_count",
    label: "Total token used",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "totalQA",
  //   label: "Total Q & A",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "latest_message",
    label: "Time Started",
    format: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
  },
];

function createData(
  username,
  noOfsession,
  totalTokenUsed,
  totalQA,
  timeStarted
) {
  return { username, noOfsession, totalTokenUsed, totalQA, timeStarted };
}

const rows = [
  createData("dummy names", "India", "IN", 1324171354, 3287263),
  createData("dummy names", "China", "CN", 1403500365, 9596961),
  createData("dummy names", "Italy", "IT", 60483973, 301340),
  createData("dummy names", "United States", "US", 327167434, 9833520),
  createData("dummy names", "Canada", "CA", 37602103, 9984670),
  createData("dummy names", "Australia", "AU", 25475400, 7692024),
  createData("dummy names", "Germany", "DE", 83019200, 357578),
  createData("dummy names", "Ireland", "IE", 4857000, 70273),
  createData("dummy names", "Mexico", "MX", 126577691, 1972550),
  createData("dummy names", "Japan", "JP", 126317000, 377973),
  createData("dummy names", "France", "FR", 67022000, 640679),
  createData("dummy names", "United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const RecentActivityTable = ({data}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="flex flex-col gap-2 mb-2.5">
        <p className="text-[#0B3CAB] text-1xl font-bold">Recent Activity</p>
        {/* <input
          type="date"
          className="w-[120px] no-calendar-icon border-[2px] border-gray-300 py-1.5 text-center outline-none"
        /> */}
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  SN
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    className="font-bold"
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "600",
                      background: "#F1F3F7",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell key={index}>{index + 1}</TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <button className="text-white px-5 bg-gradient-2 h-8 rounded-md">
                          View Chat
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default RecentActivityTable;
