import React, { useEffect, useState } from "react";
import { MdOutlinePerson } from "react-icons/md";
import { GiToken } from "react-icons/gi";
import { IoBagCheckOutline } from "react-icons/io5";
import RecentActivityTable from "./subComponent/RecentActivityTable";
import { dashboardDataApi } from "../../dashboardApi/dashboardApi";
import { notify } from "../../../../../utils/Notify";

const DashboardMain = () => {
  const [totalVisitor, setTotalVisitor] = useState(0);
  const [totalToken, setTotalToken] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [recentActivity, setRecentActivity] = useState([]);

  const handleGetDeshoortData = async() => {
    try {
      const result = await dashboardDataApi();

      if (result.data.success === true) {
        setTotalVisitor(result.data.data.count.dashboardData.userCount);
        setTotalToken(result.data.data.count.dashboardData.tokensCount);
        setTotalProduct(result.data.data.count.dashboardData.productsCount);
        setRecentActivity(result.data.data.recentUsers)
      } else if (result.data.success === false) {
        notify(result.data.message[0], "error");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetDeshoortData();
  }, [])

  return (
    <div className="mt-2.5 w-[100%] bg-white py-4 px-10">
      <h2 className="text-[#0B3CAB] text-2xl font-bold">Dashboard</h2>
      <div className="flex justify-start items-center gap-10 my-5">
        <div className="flex justify-start items-center gap-3 border-[2px] px-2.5 py-5 border-gray-300 w-[230px] cursor-pointer hover:border-[#0B3CAB]">
          <div className="w-[50px] h-[50px] flex justify-center items-center p-2.5 rounded-2xl bg-[#E3EEFD]">
            <MdOutlinePerson className="text-[#0B3CAB] text-2xl font-semibold" />
          </div>
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl text-[#0B3CAB] font-bold">{totalVisitor}</h1>
            <p className="text-xs text-[#000] font-medium">Total Visitors</p>
          </div>
        </div>
        <div className="flex justify-start items-center gap-3 border-[2px] px-2.5 py-5 border-gray-300 w-[230px] cursor-pointer hover:border-[#0B3CAB]">
          <div className="w-[50px] h-[50px] flex justify-center items-center p-2.5 rounded-2xl bg-[#E3EEFD]">
            <GiToken className="text-[#0B3CAB] text-2xl font-semibold" />
          </div>
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl text-[#0B3CAB] font-bold">{totalToken}</h1>
            <p className="text-xs text-[#000] font-medium">
              No. of Token used till date
            </p>
          </div>
        </div>
        <div className="flex justify-start items-center gap-3 border-[2px] px-2.5 py-5 border-gray-300 w-[230px] cursor-pointer hover:border-[#0B3CAB]">
          <div className="w-[50px] h-[50px] flex justify-center items-center p-2.5 rounded-2xl bg-[#E3EEFD]">
            <IoBagCheckOutline className="text-[#0B3CAB] text-2xl font-semibold" />
          </div>
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl text-[#0B3CAB] font-bold">{totalProduct}</h1>
            <p className="text-xs text-[#000] font-medium">Total Products</p>
          </div>
        </div>
      </div>
      <RecentActivityTable data={recentActivity} />
    </div>
  );
};

export default DashboardMain;
