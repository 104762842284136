import React, { useEffect, useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Loader from "../../../../../components/common/Loader";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../../utils/Notify";
import { useParams } from "react-router-dom";
import {
  deleteUserChatHistoryApi,
  getChathistoryApi,
} from "../viewChatHistoryApi/ViewChatHistoryApi";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import CoustomModal from "../../../../../components/Model/Model";

const ViewChatHistoryTable = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const navigation = useNavigate();
  const columns = [
    { field: "sn", headerName: "SN", flex: 1 },
    { field: "_id", headerName: "Session Id", flex: 1 },
    { field: "title", headerName: "Session Name", flex: 1 },
    { field: "total_tokens", headerName: "Total Token Used", flex: 1 },
    { field: "total_qa", headerName: "Total Q&A", flex: 1 },
    { field: "created_time", headerName: "Time Started", flex: 1 },
    {
      field: "",
      headerName: "Action",
      flex: 1, // Take minimal space
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <RiDeleteBin5Line
            className="text-xl text-red-500 cursor-pointer mr-1"
            onClick={() => handleDeleteClick(params.row)}
          />
          <button
            onClick={() =>
              navigation(
                `/finderscope/v1/admin/chat-history/conversation/${params.row._id}`
              )
            }
            className="text-white flex justify-center items-center h-7 px-4 bg-gradient-2 w-[6rem] rounded-md"
          >
            View Chat
          </button>
        </Box>
      ),
    },
  ];

  const [formData, setFormData] = useState({
    page: 1,
    size: 0,
    searchUsers: "",
    searchStartDate: "",
    searchEndDate: "",
    fields: [],
    sortBy: [],
    sortOrder: [],
  });

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
    // setFileId(params._id);
  };

  const handleModelClose = () => {
    setOpenDeleteModel(false);
    setSelectedRows([]);
  };

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDeleteFileHistory = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteUserChatHistoryApi(id, data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handleGetChatHistory();
    setSelectedRows([]);
    setOpenDeleteModel(false);
  };

  const handleGetChatHistory = async () => {
    setLoading(true);
    try {
      const result = await getChathistoryApi(id, formData);
      if (result.data.success) {
        setUsersData(
          result.data.conversations.map((row, index) => ({
            ...row,
            sn: index + 1, // Adding serial number
          }))
        );
        setRowCount(result.data.totalCount || 0);
      } else {
        notify(result.data.message[0], "error");
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (paginationModel) => {
    setFormData({
      ...formData,
      page: paginationModel.page + 1,
      size: paginationModel.pageSize,
    });
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  useEffect(() => {
    handleGetChatHistory();
  }, [page, pageSize]);

  return (
    <>
      <ToastContainer />
      <Loader open={loading} />
      <div className="flex justify-between items-center h-12 w-full mb-2.5 py-5">
        {selectedRows.length > 0 && (
          <div className="flex justify-end items-end ml-5">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                backgroundColor: "#E5F1F9",
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => handleDeleteClick()}
              >
                <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
              </IconButton>
            </Paper>
          </div>
        )}
      </div>
      <div className="h-[28.5rem]">
        <Paper sx={{ width: "100%", height: "100%" }}>
          <DataGrid
            rows={usersData}
            columns={columns}
            getRowId={(row) => row._id}
            paginationMode="server"
            rowCount={rowCount}
            paginationModel={{ page, pageSize }}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={selectedRows}
            onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
            onPaginationModelChange={handlePaginationChange}
            // loading={loading}
            disableColumnMenu
            pageSizeOptions={[5, 10, 25, 50, 100]}
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
      <CoustomModal
        title={"Delete User Chat History"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteFileHistory}
      >
        <p className=" text-red-800 my-10 ">
          Do you want to delete user chat history
        </p>
      </CoustomModal>
    </>
  );
};

export default ViewChatHistoryTable;
