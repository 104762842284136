import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./api/LoginApi";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../utils/Notify";
import Loader from "../../../../components/common/Loader";
import { useFormik } from "formik";
import {
  AiFillGithub,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import * as Yup from "yup";
//import finderlogo from "../../../assets/finderlogo.png";

/*****
 @object: vailidetion schema
 @description: This schema are vailidet user inputs email and password  
 *****/
const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email!") // Validates the format of the email
    .min(10, "Email must be at least 10 characters long.") // Ensures minimum length
    .max(50, "Email cannot be longer than 50 characters.") // Ensures maximum length
    .required("Please enter your email!"),
  password: Yup.string()
    .required("Please enter your password!") // Ensures the password is not empty
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,30}$/,
      "Password must be between 10 and 30 characters, and contain a mix of uppercase, lowercase, numbers, and symbols."
    ),
});

const Login = () => {
  const [openLoader, setOpenLoader] = useState(false);
  const [show, setShow] = useState(false); // this state are use for view password feature

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  useEffect(() => {
    // Open the loader
    handleOpenLoader();

    // Set a timeout to close the loader after 2 seconds
    const timer = setTimeout(() => {
      handleCloseLoader();
    }, 2000);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const navigation = useNavigate();
  // const [formData, setFormData] = useState({
  //   email: "",
  //   password: "",
  // });

  // const handleInput = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: schema,
    onSubmit: async ({ email, password }) => {
      handleOpenLoader();
      login({ email, password }) //login api
        .then((response) => response.json()) // Convert the response to JSON
        .then((data) => {
          if (data.success === true) {
            handleCloseLoader?.();
            notify(data.message[0], "success");
            localStorage.setItem("access", data.token);
            navigation("/finderscope/v1/admin/dashboard/home");
          } else if (data.success === false) {
            handleCloseLoader?.();
            notify(data.message[0], "error");
          }
        }) // Handle the response data
        .catch((error) => console.error("Error:", error));
    },
  });

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   handleOpenLoader();
  //   login(formData) //login api
  //     .then((response) => response.json()) // Convert the response to JSON
  //     .then((data) => {
  //       if (data.success === true) {
  //         handleCloseLoader?.();
  //         notify(data.message[0], "success");
  //         localStorage.setItem("access", data.token);
  //         navigation("/finderscope/v1/admin/dashboard/home");
  //       } else if (data.success === false) {
  //         handleCloseLoader?.();
  //         notify(data.message[0], "error");
  //       }
  //     }) // Handle the response data
  //     .catch((error) => console.error("Error:", error));
  // };

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div className="w-[100%] h-[100vh] bg-[#E9E9E9] relative">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="w-[70%] h-[100%] bg-gradient-3 flex justify-start items-end p-10">
        <div className="flex justify-start flex-col">
          {/* <img
            className="h-[80px] w-[120px] bg-white"
            src={finderlogo}
            alt="company_logo"
          /> */}
          <h1 className="text-white text-[80px]">Finderscope</h1>
        </div>
        <div className="absolute right-[22%] p-10 text-center top-[25%] bg-white rounded-md">
          <form onSubmit={handleSubmit}>
            <h1 className="text-2xl font-semibold mb-5">Account Sign In</h1>
            <div className="flex flex-col justify-start items-start gap-1">
              <label
                htmlFor="email"
                className="text-[#1B216A] text-sm font-bold"
              >
                Email
              </label>
              <input
                id="email"
                required
                type="email"
                onChange={handleChange}
                value={values.email}
                name="email"
                className={`w-[400px] outline-[#1B216A] pl-2.5 h-[50px] border-[2px] border-[#8692A6] ${
                  errors.email && touched.email && "!border-red-500"
                } rounded-md`}
              />
              {errors.email && touched.email && (
                <span className="text-red-500 w-full text-left text-wrap pt-1 text-xs block">
                  {errors.email}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-start items-start gap-1 mt-5">
              <label
                htmlFor="password"
                className="text-[#1B216A] text-sm font-bold"
              >
                Password
              </label>
              <div className="relative w-[400px] flex justify-end items-center">
                <input
                  id="password"
                  required
                  type={!show ? "password" : "text"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  className={`w-full outline-[#1B216A] pl-2.5 h-[50px] border-[2px] border-[#8692A6] ${
                    errors.password && touched.password && "!border-red-500"
                  } rounded-md`}
                />
                {
                  !show ? (
                    <AiOutlineEyeInvisible
                      className='absolute  right-3 z-1 cursor-pointer'
                      size={20}
                      onClick={() => setShow(true)}
                    />
                  ) : (
                    <AiOutlineEye
                      className='absolute right-3 z-1 cursor-pointer'
                      size={20}
                      onClick={() => setShow(false)}
                    />
                  )
                }
              </div>
              {errors.password && touched.password && (
                <span className="text-red-500 w-[400px] text-left  pt-1 text-xs block">
                  {errors.password}
                </span>
              )}
            </div>
            <p
              onClick={() => {
                handleOpenLoader();
                navigation("/finderscope/v1/admin/auth/forgot/password");
              }}
              className="text-right text-xs text-[#00AEFF] mt-1 cursor-pointer"
            >
              Forgot Password?
            </p>
            <button
              type="submit"
              className="text-white w-[400px] mt-7 bg-[#1B216A] h-10 rounded-md"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
