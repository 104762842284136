import React, { useState } from "react";
import {
  MdDashboardCustomize,
  MdOutlineExpandMore,
  MdOutlineExpandLess,
} from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuCalendarDays } from "react-icons/lu";
import { IoMdChatbubbles } from "react-icons/io";
import { TbCategoryFilled } from "react-icons/tb";
import { BiLogoProductHunt } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleToggleDataManagement = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Check if path matches data management section
  const isDataManagementActive = location.pathname.startsWith(
    "/finderscope/v1/admin/data-management"
  );

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleKeyDown = (event, path) => {
    if (event.key === "Enter" || event.key === " ") {
      handleNavigation(path);
    }
  };

  return (
    <div className=" p-2.5 bg-white w-[16vw] h-[99vh]">
      <div className="p-2.5 text-center mb-7 w-[7rem] h-[35px]" onClick={() => handleNavigation("/finderscope/v1/admin/dashboard/home")}>
        <img
          className="w-[100%]"
          src={"/images/logo-finfer-scope.svg"}
          alt="Finderscope Logo"
        />
      </div>

      <div
        role="button"
        aria-label="Dashboard"
        tabIndex={0}
        onClick={() => handleNavigation("/finderscope/v1/admin/dashboard/home")}
        onKeyDown={(event) =>
          handleKeyDown(event, "/finderscope/v1/admin/dashboard/home")
        }
        className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-2.5 cursor-pointer ${
          location.pathname === "/finderscope/v1/admin/dashboard/home"
            ? "bg-[#F5F8FF] text-[#003E90]"
            : "hover:bg-[#F5F8FF]"
        }`}
      >
        <MdDashboardCustomize
          className={`text-lg ${
            location.pathname === "/finderscope/v1/admin/dashboard/home"
              ? "text-[#003E90]"
              : "text-[#71839B]"
          }`}
        />
        <p
          className={`font-medium ${
            location.pathname === "/finderscope/v1/admin/dashboard/home"
              ? "text-[#003E90]"
              : "text-[#324054]"
          }`}
        >
          Dashboard
        </p>
      </div>

      <div
        role="button"
        aria-label="Data Management"
        tabIndex={0}
        onClick={handleToggleDataManagement}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ")
            handleToggleDataManagement();
        }}
        className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-2.5 cursor-pointer ${
          isDataManagementActive ? "bg-[#F5F8FF]" : "hover:bg-[#F5F8FF]"
        }`}
      >
        <HiOutlineUsers className="text-[#71839B] text-lg" />
        <p className="text-[#324054] font-medium">Data Management</p>
        {open || isDataManagementActive ? (
          <MdOutlineExpandLess />
        ) : (
          <MdOutlineExpandMore />
        )}
      </div>

      {(open || isDataManagementActive) && (
        <div className="ml-5">
          <div
            role="button"
            aria-label="Categories"
            tabIndex={0}
            onClick={() =>
              handleNavigation(
                "/finderscope/v1/admin/data-management/categories"
              )
            }
            onKeyDown={(event) =>
              handleKeyDown(
                event,
                "/finderscope/v1/admin/data-management/categories"
              )
            }
            className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-1 cursor-pointer ${
              location.pathname ===
              "/finderscope/v1/admin/data-management/categories"
                ? "bg-[#F5F8FF] text-[#003E90]"
                : "hover:bg-[#F5F8FF]"
            }`}
          >
            <TbCategoryFilled
              className={`text-lg ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/categories"
                  ? "text-[#003E90]"
                  : "text-[#71839B]"
              }`}
            />
            <p
              className={`font-medium ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/categories"
                  ? "text-[#003E90]"
                  : "text-[#324054]"
              }`}
            >
              Categories
            </p>
          </div>

          <div
            role="button"
            aria-label="Uploads"
            tabIndex={0}
            onClick={() =>
              handleNavigation("/finderscope/v1/admin/data-management/uploads")
            }
            onKeyDown={(event) =>
              handleKeyDown(
                event,
                "/finderscope/v1/admin/data-management/uploads"
              )
            }
            className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-1 cursor-pointer ${
              location.pathname ===
              "/finderscope/v1/admin/data-management/uploads"
                ? "bg-[#F5F8FF] text-[#003E90]"
                : "hover:bg-[#F5F8FF]"
            }`}
          >
            <HiOutlineUsers
              className={`text-lg ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/uploads"
                  ? "text-[#003E90]"
                  : "text-[#71839B]"
              }`}
            />
            <p
              className={`font-medium ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/uploads"
                  ? "text-[#003E90]"
                  : "text-[#324054]"
              }`}
            >
              Uploads
            </p>
          </div>

          <div
            role="button"
            aria-label="Products"
            tabIndex={0}
            onClick={() =>
              handleNavigation("/finderscope/v1/admin/data-management/products")
            }
            onKeyDown={(event) =>
              handleKeyDown(
                event,
                "/finderscope/v1/admin/data-management/products"
              )
            }
            className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-1 cursor-pointer ${
              location.pathname ===
              "/finderscope/v1/admin/data-management/products"
                ? "bg-[#F5F8FF] text-[#003E90]"
                : "hover:bg-[#F5F8FF]"
            }`}
          >
            <BiLogoProductHunt
              className={`text-lg ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/products"
                  ? "text-[#003E90]"
                  : "text-[#71839B]"
              }`}
            />
            <p
              className={`font-medium ${
                location.pathname ===
                "/finderscope/v1/admin/data-management/products"
                  ? "text-[#003E90]"
                  : "text-[#324054]"
              }`}
            >
              Products
            </p>
          </div>
        </div>
      )}

      <div
        role="button"
        aria-label="Visitors"
        tabIndex={0}
        onClick={() => handleNavigation("/finderscope/v1/admin/visitors/user")}
        onKeyDown={(event) =>
          handleKeyDown(event, "/finderscope/v1/admin/visitors/user")
        }
        className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-2.5 cursor-pointer ${
          location.pathname === "/finderscope/v1/admin/visitors/user"
            ? "bg-[#F5F8FF] text-[#003E90]"
            : "hover:bg-[#F5F8FF]"
        }`}
      >
        <LuCalendarDays
          className={`text-lg ${
            location.pathname === "/finderscope/v1/admin/visitors/user"
              ? "text-[#003E90]"
              : "text-[#71839B]"
          }`}
        />
        <p
          className={`font-medium ${
            location.pathname === "/finderscope/v1/admin/visitors/user"
              ? "text-[#003E90]"
              : "text-[#324054]"
          }`}
        >
          Visitors
        </p>
      </div>

      <div
        role="button"
        aria-label="Chat History"
        tabIndex={0}
        onClick={() => handleNavigation("/finderscope/v1/admin/chat-history")}
        onKeyDown={(event) =>
          handleKeyDown(event, "/finderscope/v1/admin/chat-history")
        }
        className={`flex justify-start items-center gap-2 px-5 py-2.5 mb-2.5 cursor-pointer ${
          location.pathname === "/finderscope/v1/admin/chat-history"
            ? "bg-[#F5F8FF] text-[#003E90]"
            : "hover:bg-[#F5F8FF]"
        }`}
      >
        <IoMdChatbubbles
          className={`text-lg ${
            location.pathname === "/finderscope/v1/admin/chat-history"
              ? "text-[#003E90]"
              : "text-[#71839B]"
          }`}
        />
        <p
          className={`font-medium ${
            location.pathname === "/finderscope/v1/admin/chat-history"
              ? "text-[#003E90]"
              : "text-[#324054]"
          }`}
        >
          Chat History
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
