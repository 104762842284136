import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MdCancelScheduleSend } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  bgcolor: "background.paper",
  border: "2px solid #ccc",
  boxShadow: 24,
  p: 4,
};

export default function CoustomModal({ title, open, handleClose, handleSubmit, children }) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          backdropFilter: "blur(10px)",
          borderRadius: "10px",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between">
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
              >
                {title}
              </Typography>
              <Button onClick={handleClose}>X</Button>
            </div>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              variant="body2"
              component="div"
            >
              {children}
              <div className="flex justify-end items-center mt-10">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    startIcon={<MdCancelScheduleSend />}
                    onClick={handleClose}
                    sx={{
                      background: "linear-gradient(285.17deg, #CED7FF -3.31%, #87A1FF 80.62%)",
                      "&:hover": {
                        background: "background-color: linear-gradient(285.17deg, #AEBBFF -3.31%, #6B88FF 80.62%);",
                      },
                      color: "#ffffff",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<IoMdSend />}
                    onClick={handleSubmit}
                    sx={{
                      background: "linear-gradient(285.17deg, #CED7FF -3.31%, #87A1FF 80.62%)",
                      "&:hover": {
                        background: "background-color: linear-gradient(285.17deg, #AEBBFF -3.31%, #6B88FF 80.62%);",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
