import React, { useEffect, useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Loader from "../../../../../components/common/Loader";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../../utils/Notify";
import { getAllChatHistoryApi } from "../allChatHistoryApi/AllChatHistoryApi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AllChatHistoryTable = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const navigation = useNavigate();

  const [formData, setFormData] = useState({
    page: 1,
    size: 0,
    searchUsers: "",
    searchStartDate: "",
    searchEndDate: "",
    fields: [],
    sortBy: [],
    sortOrder: [],
  });

  const handleGetChatHistory = async () => {
    setLoading(true);
    try {
      const result = await getAllChatHistoryApi(formData);

      if (result.data.success) {
        setUsersData(
          result.data.users.map((row, index) => ({
            ...row,
            sn: index + 1, // Adding serial number
          }))
        );
        setRowCount(result.data.totalCount || 0);
      } else {
        notify(result.data.message, "error");
      }
    } catch (error) {
      console.error(error);
      notify("Failed to fetch chat history", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetChatHistory();
  }, [page, pageSize, filterModel]);

  const columns = [
    { field: "sn", headerName: "SN", flex: 1 },
    { field: "name", headerName: "Full Name", flex: 1 },
    { field: "created_date", headerName: "Date", flex: 1 },
    { field: "sessionsCount", headerName: "No. of Sessions", flex: 1 },
    { field: "total_tokens", headerName: "Total Tokens Used", flex: 1 },
    {
      field: "",
      headerName: "Action",
      flex: 1, // Take minimal space
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <MdOutlineRemoveRedEye
            className="text-xl text-[#1B216A] cursor-pointer"
            onClick={() =>
              navigation(
                `/finderscope/v1/admin/chat-history/view/${params.row._id}`
              )
            }
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <Loader open={loading} />
      <div className="h-[32rem]">
        <Paper sx={{ width: "auto", height: "100%" }}>
          <DataGrid
            rows={usersData}
            columns={columns}
            getRowId={(row) => row._id}
            paginationMode="server"
            rowCount={rowCount}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(model) => {
              setFormData({
                ...formData,
                page: model.page + 1,
                size: model.pageSize,
              });
              setPage(model.page);
              setPageSize(model.pageSize);
            }}
            // loading={loading}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableColumnMenu
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
    </>
  );
};

export default AllChatHistoryTable;
