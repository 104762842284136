// /src/App.jsx
import React from "react";
import AppRoutes from "./routes/routes"; // Import your main routes file
import "./App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { BrowserRouter as Router } from "react-router-dom"; // Ensure Router is here only

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <AppRoutes /> {/* Routes component will handle page navigation */}
        </Router>
      </LocalizationProvider>
    </div>
  );
};

export default App;
