import React from "react";
import VisitorsTable from "./subComponent/VisitorsTable";

const VisitorsMain = () => {
  return (
    <div className="w-[100%] ">
      <div className="bg-white px-10 py-2.5 my-2.5">
        <h2 className="text-[#0B3CAB] text-2xl font-bold">Visitors</h2>
      </div>
      <div className="bg-white px-10 py-2.5 ">
        <VisitorsTable />
      </div>
    </div>
  );
};

export default VisitorsMain;
