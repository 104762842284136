import React, { useEffect, useRef, useState } from "react";
import RenderListItem from "./RenderListItem";
import NewLogo from "../../../../../assets/newLogo.png";
import { marked } from "marked";
import { useParams } from "react-router-dom";
import { getConversationApi } from "../conversationApi/ConversationApi";
const ConversationInterface = () => {
  const { id } = useParams();
  const chatParent = useRef(null);
  const [messages, setMessages] = useState([]);

  const handleGetChatHistory = async () => {
    try {
      const result = await getConversationApi(id);

      if (result.data) {
        setMessages(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetChatHistory();
  }, []);

  useEffect(() => {
    const domNode = chatParent.current;
    if (domNode) domNode.scrollTop = domNode.scrollHeight;
  }, [messages]);
  return (
    <div
      className={`relative h-[80vh] bg-white w-[100%] px-[120px] overflow-scroll p-5  chat-box-container`}
      ref={chatParent} // Reference for scrolling
    >
      <div className="flex justify-start items-start mt-10 mb-[1.25rem]">
        <img src={NewLogo} alt="logo" width={40} height={25} />
        {/* Logo */}
        <p
          id="chat-texteditor"
          className="text-left px-3 bg-[#fff] rounded text-[#343541] inline-block font-normal text-[1.12rem]"
        >
          Welcome to Finderscope What would you like to Search?
        </p>
      </div>
      {messages.map((message, index) => {
        const match = message.content.match(/```json\s*([\s\S]*?)\s*```/);
        const jsonContent = match ? match[1] : ""; // Extract JSON content if present

        const isRenderListItem = !!match; // Determine if content should be rendered as list

        return (
          <div
            key={index}
            style={{
              textAlign: message.role === "user" ? "right" : "left", // Align based on message role
              justifyContent:
                message.role === "user" ? "flex-end" : "flex-start", // Align based on message role
              float: message.role === "user" ? "right" : "left",
              width: message.role === "user" ? "100%" : "90%",
              paddingLeft: message.role === "user" ? "100px" : "0",
            }}
            className="relative my-5 flex items-start hide-scrollbar markdown-body"
          >
            {/* Render list item or normal message */}
            {isRenderListItem ? (
              <RenderListItem
                items={jsonContent} // Render JSON content as list
              />
            ) : (
              <>
                {message.role !== "user" ? (
                  <img
                    src={NewLogo}
                    alt="logo"
                    className="inline-block "
                    width={40}
                    height={25}
                  />
                ) : null}
                <p
                  style={{
                    color: "#343541",
                    fontSize: "1.02rem",
                    fontWeight: "400",
                    textAlign: "left",
                    backgroundColor: isRenderListItem
                      ? "0"
                      : message.role === "user"
                      ? "#F4F4F4" // Background for user messages
                      : "white", // Background for assistant messages
                    padding: message.role === "user" ? "0.5rem 1.25rem" : "",
                    marginBottom: "0",
                    lineHeight: "1.625rem",
                  }}
                  className="px-3 mb-0 bg-[#fff] rounded text-[#343541] font-normal text-sm"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(message.content),
                    }}
                  />
                </p>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ConversationInterface;
