import React from "react";
import Sidebar from "../../../../components/common/Sidebar";
import Navbar from "../../../../components/common/Navbar";
import DashboardMain from "./component/DashboardMain";

const DashboardAdmin = () => {
  return (
    <div >
      
        <DashboardMain />
      </div>
    
  );
};

export default DashboardAdmin;
