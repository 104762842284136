import React, { useEffect } from "react";
import ProfileMain from "./component/ProfileMain";
import Validate from "../../../../constants/Validate";
import Layout from "../../../../components/layout/Layout";
import { useHandleValidateRefresh } from "../../../../constants/RefreshValidate";
const ViewProfile = () => {
  const isLoggedIn = !!localStorage.getItem("access");
  const handleValidateRefresh = useHandleValidateRefresh();
  useEffect(() => {
    handleValidateRefresh();
  }, []);
  return isLoggedIn ? (
    <Layout>
      <ProfileMain />
    </Layout>
  ) : (
    <Validate />
  );
};

export default ViewProfile;
