import axios from 'axios'
import { baseUrl } from '../config/BaseUrl';

export const getDataAPI = async (url, token, headers) => {
    const res = await axios.get(`${baseUrl}/api/${url}`, {
        headers: { Authorization: token, ...headers}
    })
    return res;
}

export const postDataAPI = async (url, post, token) => {
    const res = await axios.post(`${baseUrl}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const putDataAPI = async (url, post, token) => {
    const res = await axios.put(`${baseUrl}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const patchDataAPI = async (url, post, token) => {
    const res = await axios.patch(`${baseUrl}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const deleteDataAPI = async (url, token) => {
    const res = await axios.delete(`${baseUrl}/api/${url}`, {
        headers: { Authorization: token}
    })
    return res;
}