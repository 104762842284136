import React, { useEffect, useState } from "react";
import { getProfile, updateProfile } from "../api/profileApi";
import { notify } from "../../../../../utils/Notify";
import { ToastContainer } from "react-toastify";
import ChangePasswordPop from "./subComponent/ChangePasswordPop";
import Loader from "../../../../../components/common/Loader";

const ProfileMain = () => {
  const [openLoader, setOpenLoader] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const [isEditable, setIsEditable] = useState(false); // Initial state is false (view mode)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    date_of_birth: "",
    gender: "",
    address: "",
    date_format: "",
    currency: "",
  });

  const toggleEditMode = () => {
    setIsEditable(!isEditable); // Toggle between view and edit mode
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (name === "date_of_birth") {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      let formattedDate = "";
      if (formData.date_format === "DD-MM-YYYY") {
        formattedDate = `${day}-${month}-${year}`;
      } else {
        formattedDate = `${day}/${month}/${year}`;
      }

      setFormData({
        ...formData,
        [name]: formattedDate,
      });
    } else if (name === "date_format") {
      setFormData({
        ...formData,
        [name]: value,
        date_of_birth: formData.date_of_birth.replaceAll("-", "/"),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleGetProfile = () => {
    handleOpenLoader();
    getProfile()
      .then((response) => response.json())
      .then((data) => {
        handleCloseLoader();
        setFormData(data.admin);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  const convertToYYYYMMDD = (dateString) => {
    let formatDate = [];
    if (dateString[2] === "-") {
      formatDate = dateString.split("-");
    } else {
      formatDate = dateString.split("/");
    }
    // Split the date string by the hyphen to extract day, month, year
    const [day, month, year] = formatDate;

    // Create a new date object in the format yyyy/mm/dd
    const date = new Date(`${year}-${month}-${day}`);

    // Format the date back to yyyy-mm-dd
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    return formattedDate;
  };

  const handleUpdateProfile = () => {
    handleOpenLoader();
    updateProfile(formData)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader();
          setFormData(data.admin);
          notify(data.message[0], "success");
          setIsEditable(false);
        } else if (data.success === false) {
          handleCloseLoader();
          notify(data.message[0], "error");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="my-2.5 w-[100%] bg-white py-5 px-10">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="flex justify-between items-center">
        <p className="text-[#0B3CAB] text-2xl font-bold">Profile</p>
        <div className="flex justify-center items-center gap-10">
          <button
            onClick={isEditable ? handleUpdateProfile : handleOpen}
            className="text-white px-5 bg-gradient-2 h-10 rounded-md"
          >
            {isEditable ? "Update Profile" : "Change Password"}
          </button>
          <button
            className="text-white px-5 bg-gradient-2 h-10 rounded-md"
            onClick={toggleEditMode}
          >
            {isEditable ? "Cancel" : "Edit"}
          </button>
        </div>
      </div>
      <div className="flex justify-start items-start gap-10 mt-12 pb-10">
        <img
          className="h-[200px] w-[180px] rounded-md"
          src="https://images.unsplash.com/photo-1542452376175-82b6fb643412?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cGVyc29ufGVufDB8fDB8fHww"
          alt="profile_img"
        />
        <form className="flex flex-col gap-10">
          <div className="w-[100%] border-[1px] p-3 rounded-md border-[#D1D5DC]">
            <h2 className="text-md text-black font-bold">Admin Details</h2>
            <hr className="w-[100%]" />
            <div className="flex justify-center gap-[50px] items-center pr-5">
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="first_name"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  First Name
                </label>
                <input
                  id="first_name"
                  required
                  type="text"
                  onChange={handleInput}
                  name="first_name"
                  value={formData.first_name}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable} // Disable the input if not in edit mode
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="last_name"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Last Name
                </label>
                <input
                  id="last_name"
                  required
                  type="text"
                  onChange={handleInput}
                  name="last_name"
                  value={formData.last_name}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="mobile"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Phone Number
                </label>
                <input
                  id="mobile"
                  required
                  type="text"
                  onChange={handleInput}
                  name="mobile"
                  value={formData.mobile}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="email"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Email
                </label>
                <input
                  id="email"
                  required
                  type="email"
                  onChange={handleInput}
                  name="email"
                  value={formData.email}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="w-[100%] border-[1px] p-3 rounded-md border-[#D1D5DC]">
            <h2 className="text-md text-black font-bold">Personal Info</h2>
            <hr className="w-[100%]" />
            <div className="flex justify-start gap-[50px] items-center pr-5">
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="date_of_birth"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Date of Birth
                </label>
                <input
                  id="date_of_birth"
                  required
                  type="date"
                  onChange={handleInput}
                  name="date_of_birth"
                  value={convertToYYYYMMDD(formData.date_of_birth)}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="gender"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Gender
                </label>
                <select
                  id="gender"
                  name="gender"
                  onChange={handleInput}
                  value={formData.gender}
                  className="w-[200px] outline-[#1B216A] px-2 h-[40px] border-[1px] rounded-md border-[#9CA3AF]"
                  disabled={!isEditable}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Female</option>
                </select>
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="address"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Address
                </label>
                <input
                  id="address"
                  required
                  type="text"
                  onChange={handleInput}
                  name="address"
                  value={formData.address}
                  className={`w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="w-[100%] border-[1px] p-3 rounded-md border-[#D1D5DC]">
            <h2 className="text-md text-black font-bold">Settings</h2>
            <hr className="w-[100%]" />
            <div className="flex justify-start gap-[50px] items-center pr-5">
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="date_format"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Date Format
                </label>
                <select
                  id="date_format"
                  name="date_format"
                  onChange={handleInput}
                  value={formData.date_format}
                  className="w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]"
                  disabled={!isEditable}
                >
                  <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                  <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                </select>
              </div>
              <div className="flex flex-col justify-start items-start gap-1 mt-5">
                <label
                  htmlFor="currency"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Currency
                </label>
                <select
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleInput}
                  className="w-[200px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]"
                  disabled={!isEditable}
                >
                  <option value="" disabled>
                    Select Currency
                  </option>
                  <option value="USD">Dollar (USD)</option>
                  <option value="EURO">Euro (EUR)</option>
                  <option value="INR">Indian Rupee (INR)</option>
                  <option value="GBP">Great Britain Pound (GBP)</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ChangePasswordPop
        open={open}
        handleClose={handleClose}
        handleCloseLoader={handleCloseLoader}
        handleOpenLoader={handleOpenLoader}
      />
    </div>
  );
};

export default ProfileMain;
