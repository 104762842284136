import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/common/Loader";
import { notify } from "../../../../../utils/Notify";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteChatHistoryApi,
  fetchUserbyId,
  getChathistoryApi,
} from "../../visitorsApi/VisitorsApi";
import {
  Box,
  Divider,
  Grid,
  Grid2,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";
import CoustomModal from "../../../../../components/Model/Model";
const UserMain = () => {
  const [openLoader, setOpenLoader] = useState(false);
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState();
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigation = useNavigate();
  const { id } = useParams();

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
  };

  const handleModelClose = () => {
    setOpenDeleteModel(false);
  };

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDeleteChatHistory = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteChatHistoryApi(id, data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handleGetChatHistory();
    setSelectedRows([]);
    setOpenDeleteModel(false);
  };

  const columns = [
    { field: "sn", headerName: "SN", flex: 1 },
    { field: "_id", headerName: "Session Id", flex: 1 },
    { field: "title", headerName: "Session Name", flex: 1 },
    { field: "total_tokens", headerName: "Total Token Used", flex: 1 },
    { field: "total_qa", headerName: "Total Q&A", flex: 1 },
    { field: "created_time", headerName: "Time Started", flex: 1 },
    {
      field: "",
      headerName: "Action",
      flex: 1, // Take minimal space
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <RiDeleteBin5Line
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDeleteClick(params.row)}
          />
          <button
            onClick={() =>
              navigation(
                `/finderscope/v1/admin/chat-history/conversation/${params.row._id}`
              )
            }
            className="text-white flex justify-center items-center h-7 px-4 bg-gradient-2 w-[6rem] rounded-md"
          >
            View Chat
          </button>
        </Box>
      ),
    },
  ];

  const handlePaginationChange = (paginationModel) => {
    setFormData({
      ...formData,
      page: paginationModel.page + 1,
      size: paginationModel.pageSize,
    });
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    searchStartDate: null,
    searchEndDate: null,
    fields: [],
    sortBy: ["created_at"],
    sortOrder: ["desc"],
  });

  const handleGetUser = () => {
    handleOpenLoader();
    fetchUserbyId(id)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader();
          setFormData(data.user);
        } else if (data.success === false) {
          handleCloseLoader();
          notify(data.message[0], "error");
        }
      })
      .catch((err) => console.error(err));
  };

  const handleGetChatHistory = async () => {
    setLoading(true);
    try {
      const result = await getChathistoryApi(id, formData);
      if (result.data.success) {
        setUsersData(
          result.data.conversations.map((row, index) => ({
            ...row,
            sn: index + 1, // Adding serial number
          }))
        );
        setRowCount(result.data.totalCount || 0);
      } else {
        notify(result.data.message[0], "error");
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetChatHistory();
  }, [page, pageSize]);

  useEffect(() => {
    handleGetUser();
  }, []);

  return (
    <div className="my-2.5 w-[100%] bg-white py-5 px-10 ">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mt: "12px",
          pb: "10px",
        }}
      >
        <img
          className="h-[200px] w-[180px] rounded-md"
          src="https://images.unsplash.com/photo-1542452376175-82b6fb643412?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cGVyc29ufGVufDB8fDB8fHww"
          alt="profile_img"
        />
        <Box
          sx={{
            gap: "10px",
            // mt: "12px",
            pb: "30px",
            border: "1px solid #D1D5DC",
            borderRadius: "5px",
            padding: "10px",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#000", fontWeight: "bold", fontSize: "1rem" }}
          >
            User Details
          </Typography>
          <Divider
            sx={{
              mt: "12px",
              mb: "12px",
              border: "1px solid #D1D5DC",
            }}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              width: "100%",
              mb: "20px",
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "#949494",
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                >
                  Name
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "1.23rem",
                  }}
                >
                  {formData.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "#949494",
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                >
                  Email
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "1.23rem",
                  }}
                >
                  {formData.email}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "#949494",
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                >
                  Register Date
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "1.23rem",
                  }}
                >
                  {formData.created_date}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "#949494",
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                >
                  Status
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "1.23rem",
                  }}
                >
                  {formData.status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: "12px",
          mb: "12px",
          border: "1px solid #D1D5DC",
        }}
      />
      <div className="flex gap-2 mb-2.5">
        <p className="text-1xl font-bold">Chat History</p>
        {selectedRows.length > 0 && (
          <div className="flex justify-end items-end ml-5">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                backgroundColor: "#E5F1F9",
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => handleDeleteClick()}
              >
                <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
              </IconButton>
            </Paper>
          </div>
        )}
      </div>
      <Paper sx={{ width: "100%", height: "22.5rem" }}>
        <DataGrid
          rows={usersData}
          columns={columns}
          getRowId={(row) => row._id}
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={handlePaginationChange}
          loading={loading}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectedRows}
          onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
          disableColumnMenu
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sx={{
            border: 0,
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
        />
      </Paper>
      <CoustomModal
        title={"Delete Category"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteChatHistory}
      >
        <p className=" text-red-800 my-10 ">Do you want to delete category</p>
      </CoustomModal>
    </div>
  );
};

export default UserMain;
