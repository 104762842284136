// /src/pages/ViewProducts.js
import React from "react";
import ProductMain from "./components/ProductMain";

const ViewProducts = () => {
  return (
    <div>
      <ProductMain />
    </div>
  );
};

export default ViewProducts;
