import React, { useEffect } from "react";
import DashboardMain from "./component/DashboardMain";
import { useHandleValidateRefresh } from "../../../../constants/RefreshValidate";
import Layout from "../../../../components/layout/Layout";
import Validate from "../../../../constants/Validate";

const DashboardAdmin = () => {
  const isLoggedIn = !!localStorage.getItem("access");
  const handleValidateRefresh = useHandleValidateRefresh();
  useEffect(() => {
    handleValidateRefresh();
  }, []);

  return isLoggedIn ? (
    <Layout>
      <DashboardMain />
    </Layout>
  ) : (
    <Validate />
  );
};

export default DashboardAdmin;
