import React from "react";
import { Routes, Route } from "react-router-dom";
import AllChatHistory from "./allChatHistory/AllChatHistory";
import ViewChatHistory from "./viewChatHistory/ViewChatHistory";
import ConversationChatHistory from "./conversationChatHistory/ConversationChatHistory";
const ChatHistoryRoutes = () => {
  return (
    <Routes>
      <Route path="all" element={<AllChatHistory />} />
      <Route path="view/:id" element={<ViewChatHistory />} />
      <Route path="conversation/:id" element={<ConversationChatHistory />} />
    </Routes>
  );
};

export default ChatHistoryRoutes;
