import { useState } from "react";
import { forgotPassword } from "./api/forgotPassword";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../utils/Notify";
import Loader from "../../../../components/common/Loader";
//import finderlogo from "../../../assets/finderlogo.png";

const ForgotPassword = () => {
  const [openLoader, setOpenLoader] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    handleOpenLoader();
    forgotPassword(formData) //forgot password api
      .then((response) => response.json()) // Convert the response to JSON
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader();
          notify(data.message[1], "success");
        } else if (data.success === false) {
          handleCloseLoader();
          notify(data.message[0], "error");
        }
      }) // Handle the response data
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="w-[100%] h-[100vh] bg-[#E9E9E9] relative">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="w-[70%] h-[100%] bg-[#1B216A] flex justify-start items-end p-10">
        <div className="flex justify-start flex-col">
          {/* <img
            className="h-[80px] w-[120px] bg-white"
            src={finderlogo}
            alt="company_logo"
          /> */}
          <h1 className="text-white text-[80px]">Finderscope</h1>
        </div>
        <div className="absolute right-[22%] p-10 text-center top-[25%] bg-white rounded-md">
          <form onSubmit={handleForgotPassword}>
            <h1 className="text-2xl font-semibold mb-5">Forgot Password</h1>
            <div className="flex flex-col justify-start items-start gap-1">
              <label
                htmlFor="email"
                className="text-[#1B216A] text-sm font-bold"
              >
                Email
              </label>
              <input
                id="email"
                required
                type="email"
                onChange={handleInput}
                name="email"
                value={formData.email}
                className="w-[400px] outline-[#1B216A] pl-2.5 h-[50px] border-[2px] border-[#8692A6] rounded-md"
              />
            </div>
            <button
              type="submit"
              className="text-white w-[400px] mt-7 bg-[#1B216A] h-10 rounded-md"
            >
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
