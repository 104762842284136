import React from "react";
import Navbar from "../../../../components/common/Navbar";
import Sidebar from "../../../../components/common/Sidebar";
import VisitorsMain from "./component/VisitorsMain";

const ViewVisitors = () => {
  return (
    <div>
   
        <VisitorsMain />
      </div>
  );
};

export default ViewVisitors;
