import React from "react";
import Sidebar from "../../../../components/common/Sidebar";
import Navbar from "../../../../components/common/Navbar";
import CategoriesMain from "./components/CategoriesMain";

const ViewCategories = () => {
  return (
    <div>
      <CategoriesMain />
    </div>
  );
};

export default ViewCategories;
