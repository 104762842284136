import React, { useEffect, useState } from "react";
import { resetPassword, verifyTokenId } from "./api/resetPasswordApi";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../utils/Notify";
import Loader from "../../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Logo from "../../../../assets/finderlogo.png"

const schema = Yup.object().shape({
  new_password: Yup.string()
    .required("Please enter your password!") // Ensures the password is not empty
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,30}$/,
      "Password must be between 8 and 30 characters, and contain a mix of uppercase, lowercase and numbers"
    ),
  retype_new_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), undefined],
      "Passwords must match new password"
    ) // Ensures confirmPassword matches password
    .required("Please confirm your password!"),
});

const ResetPassword = () => {
  const navigation = useNavigate();
  const refresh =
    typeof window !== "undefined" ? localStorage.getItem("refresh") : null;
  useEffect(() => {
    localStorage.removeItem("login");
    if (refresh) {
      navigation("/finderscope/v1/admin/dashboard/home");
    }
  }, []);
  const [openLoader, setOpenLoader] = useState(false);
  const { id } = useParams();
  const [userId, setUserId] = useState("");

  const verifyToken = async (token) => {
    verifyTokenId(token) // verify token api
      .then((response) => response.json()) // Convert the response to JSON
      .then((data) => {
        if (data.success) {
          notify(data.message[0], "success");
          setUserId(data.admin._id);
        }
      }) // Handle the response data
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    // Replace with the actual JWT token you get from params.
    const token = Array.isArray(id) ? id[0] : id; // If it's an array, take the first element

    if (token) {
      verifyToken(token); // call the verify token api
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false); // state for password view and hide
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const [formData, setFormData] = useState({
    _id: "",
    new_password: "",
    retype_new_password: "",
  });

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      _id: userId,
    });
  };

  // const handleResetPassword = (e) => {
  //   e.preventDefault();
  //   handleOpenLoader();
  //   resetPassword(formData) //reset password api
  //     .then((response) => response.json()) // Convert the response to JSON
  //     .then((data) => {
  //       if (data.success === true) {
  //         handlehandleCloseLoader?.();
  //         notify(data.message[0], "success");
  //         navigation("/");
  //       } else if (data.success === false) {
  //         handlehandleCloseLoader?.();
  //         notify(data.message[0], "error");
  //       }
  //     }) // Handle the response data
  //     .catch((error) => console.error("Error:", error));
  // };

  const formik = useFormik({
    initialValues: { new_password: "", retype_new_password: "" },
    validationSchema: schema,
    onSubmit: ({ new_password, retype_new_password }) => {
      handleOpenLoader();
      resetPassword({ new_password, retype_new_password, _id: userId }) //login api
        .then((response) => response.json()) // Convert the response to JSON
        .then((data) => {
          if (data.success) {
            handleCloseLoader?.();
            notify(data.message[0], "success");
            setTimeout(() => {
              navigation("/");
            }, 1600);
          } else if (data.success === false) {
            notify(data.message[0], "error");
            handleCloseLoader?.();
          }
        }) // Handle the response data
        .catch((error) => console.error("Error:", error));
    },
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div className="w-[100%] h-[100vh] bg-[#E9E9E9] relative">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="w-full h-full bg-gradient-4 flex justify-start p-10">
        <div className="flex justify-start flex-col">
        <img src={Logo} alt="Finder Scope Logo" width={210} height={100} />
          {/* <h1 className="text-white text-[80px]">Finderscope</h1> */}
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 text-center bg-white rounded-md">
          <form onSubmit={handleSubmit}>
            <h1 className="text-4xl font-inter font-semibold mb-5">Reset Password</h1>
            <div className="flex flex-col justify-start items-start gap-1 ">
              <label
                htmlFor="new_password"
                className="text-[#0066FF] text-sm font-bold"
              >
                Create Password<span className="text-[#e03b3b]">*</span>
              </label>
              <div className="relative w-[400px] flex justify-end items-center">
                <input
                  type={!showPassword ? "password" : "text"} // Input field for the new password
                  placeholder="Contraseña*"
                  name="new_password"
                  id="new_password"
                  value={values.new_password}
                  onChange={handleChange} // Handles password input change
                  className={`w-[400px] outline-[#0066FF] pl-2.5 h-[50px] border-[2px] border-[#8692A6] ${
                    errors.new_password &&
                    touched.new_password &&
                    "!border-red-500"
                  } rounded-md`}
                />

                {!showPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </div>
              {errors.new_password && touched.new_password && (
                <span className="text-red-500 w-[400px] pt-1 text-xs block">
                  {errors.new_password}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-start items-start gap-1 mt-5">
              <label
                htmlFor="retype_new_password"
                className="text-[#0066FF] text-sm font-bold"
              >
                Confirm Password<span className="text-[#e03b3b]">*</span>
              </label>
              <div className="relative w-[400px] flex justify-end items-center">
                <input
                  type={!showConfirmPassword ? "password" : "text"} // Input field for confirming the new password
                  placeholder="Repetir contraseña*"
                  name="retype_new_password"
                  id="retype_new_password"
                  value={values.retype_new_password}
                  onChange={handleChange} // Handles password input change
                  className={`w-full h-[55px] pl-5 text-[#000] outline-[#0066FF] border-2 rounded-md ${
                    (errors.retype_new_password &&
                      touched.retype_new_password) ||
                    (values.retype_new_password != values.new_password &&
                      values.retype_new_password.length > 0)
                      ? "border-red-500"
                      : "border-gray-400"
                  }`}
                />
                {!showConfirmPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                )}
              </div>
              {errors.retype_new_password && touched.retype_new_password ? (
                <span className="text-red-500 w-[400px] text-xs block">
                  {errors.retype_new_password}
                </span>
              ) : values.retype_new_password !== values.new_password &&
                values.retype_new_password.length > 0 ? (
                <span className="text-red-500 w-[400px] text-xs block">
                  Passwords do not match.
                </span>
              ) : null}
            </div>
            <button
              type="submit"
              className="text-white px-12 mt-7 bg-gradient-2 h-10 rounded-md"
            >
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
