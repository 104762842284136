import { baseUrl } from "../../../../../config/BaseUrl";

export const getProfile = () => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  return fetch(`${baseUrl}/api/admin/profile`, {
    method: "GET",
    headers: {
      Authorization: `${token}`, // Add the token to the Authorization header
    },
  });
};

export const updateProfile = (formData) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  return fetch(`${baseUrl}/api/admin/update-profile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`, // Add the token to the Authorization header
    },
    body: JSON.stringify(formData),
  });
};

export const changePassword = (formData) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  return fetch(`${baseUrl}/api/admin/change-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`, // Adding the token to the Authorization header
    },
    body: JSON.stringify(formData), // Assuming formData is your payload
  });
};
