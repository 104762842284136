import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { changePassword } from "../../api/profileApi";
import { notify } from "../../../../../../utils/Notify";
import { jwtDecode } from "jwt-decode";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

/*****
 @object: vailidetion schema
 @description: This schema are vailidet user inputs password  
 *****/
const schema = Yup.object().shape({
  current_password: Yup.string()
    .required("Please enter your password!") // Ensures the password is not empty
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,30}$/,
      "Password must be between 10 and 30 characters, and contain a mix of uppercase, lowercase, numbers, and symbols."
    ),
  new_password: Yup.string()
    .required("Please enter your password!") // Ensures the password is not empty
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,30}$/,
      "Password must be between 10 and 30 characters, and contain a mix of uppercase, lowercase, numbers, and symbols."
    ),
  retype_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), undefined], "Passwords must match") // Ensures confirmPassword matches password
    .required("Please confirm your password!"),
});

const ChangePasswordPop = ({
  open,
  handleClose,
  handleCloseLoader,
  handleOpenLoader,
}) => {
  const [decodedToken, setDecodedToken] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // state for current password view and hide
  const [showPassword, setShowPassword] = useState(false); // state for password view and hide
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // state for confirm password view and hide
  useEffect(() => {
    // Step 2: Retrieve the token from localStorage when the component mounts
    const storedToken = localStorage.getItem("access") || "";
    if (storedToken) {
      try {
        // Step 3: Decode the token and store it in state
        const decoded = jwtDecode(storedToken);
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Failed to decode token", error); // Handle decoding errors
      }
    }
  }, []);

  // const inputFields = {
  //   _id: "",
  //   current_password: "",
  //   new_password: "",
  //   retype_new_password: "",
  // };

  // const [formData, setFormData] = useState(inputFields);

  // // Updates formData state based on user input
  // const handleInput = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //     _id: decodedToken?._id || "",
  //   });
  // };

  // const handleChangePassword = (e) => {
  //   e.preventDefault();
  //   handleOpenLoader();
  //   changePassword(formData)
  //     .then((response) => response.json()) // Convert the response to JSON
  //     .then((data) => {
  //       if (data.success) {
  //         handleCloseLoader();
  //         notify(data.message[0], "success");
  //         setFormData(inputFields);
  //         handleClose();
  //       } else if (data.success === false) {
  //         handleCloseLoader();
  //         notify(data.message[0], "error");
  //       }
  //     }) // Handle the response data
  //     .catch((error) => console.error("Error:", error));
  // };

  const initialValues = {
    current_password: "",
    new_password: "",
    retype_new_password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (
      { current_password, new_password, retype_new_password },
      { resetForm }
    ) => {
      handleOpenLoader();
      changePassword({
        current_password,
        new_password,
        retype_new_password,
        _id: decodedToken?._id || "",
      })
        .then((response) => response.json()) // Convert the response to JSON
        .then((data) => {
          if (data.success) {
            handleCloseLoader();
            notify(data.message[0], "success");
            resetForm(); // Reset the form fields after successful submission
            handleClose();
          } else if (data.success === false) {
            handleCloseLoader();
            resetForm(); // Reset the form fields after successful submission
            notify(data.message[0], "error");
          }
        }) // Handle the response data
        .catch((error) => console.error("Error:", error));
    },
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white outline-none rounded-lg p-4">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          {/* Form container */}
          <div className="flex justify-center items-center gap-5 flex-col mt-[20px] ">
            <h2 className="font-semibold text-lg">Cambiar la contraseña</h2>
            {/* Input fields container */}
            <div className="w-[90%]">
              <div className="w-full relative flex justify-end items-center">
                <input
                  type={!showCurrentPassword ? "password" : "text"} // Input field for old password
                  placeholder="Contraseña anterior"
                  name="current_password"
                  id="current_password"
                  required
                  onChange={handleChange} // Handles input change
                  value={values.current_password}
                  className={`w-full h-12 pl-5 text-[#000] outline-[#367DFD] border-2 border-gray-400 ${
                    errors.current_password &&
                    touched.current_password &&
                    "!border-red-500"
                  } rounded-md`}
                />
                {!showCurrentPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute  right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowCurrentPassword(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowCurrentPassword(false)}
                  />
                )}
              </div>
              {errors.current_password && touched.current_password && (
                <span className="text-red-500 w-[90%] pt-1 text-xs block">
                  {errors.current_password}
                </span>
              )}
            </div>
            <div className="w-[90%]">
              <div className="w-full relative flex justify-end items-center">
                <input
                  type={!showPassword ? "password" : "text"} // Input field for the new password
                  placeholder="Contraseña*"
                  id="new_password"
                  required
                  onChange={handleChange} // Handles input change
                  name="new_password"
                  value={values.new_password}
                  className={`w-full h-12 pl-5 text-[#000] outline-[#367DFD] border-2 border-gray-400 ${
                    errors.current_password &&
                    touched.current_password &&
                    "!border-red-500"
                  } rounded-md`}
                />
                {!showPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute  right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </div>
              {errors.new_password && touched.new_password && (
                <span className="text-red-500 w-[90%] text-xs block">
                  {errors.new_password}
                </span>
              )}
            </div>
            <div className="w-[90%]">
              <div className="w-full relative flex justify-end items-center">
                <input
                  type={!showConfirmPassword ? "password" : "text"} // Input field for confirming the new password
                  placeholder="Repetir contraseña"
                  id="retype_new_password"
                  required
                  name="retype_new_password"
                  onChange={handleChange} // Handles input change
                  value={values.retype_new_password}
                  className="w-full h-12 pl-5 text-[#000] outline-[#367DFD] border-2 border-gray-400 rounded-md"
                />
                {!showConfirmPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute  right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                )}
              </div>
              {errors.retype_new_password && touched.retype_new_password && (
                <span className="text-red-500 w-[90%]  text-xs block">
                  {errors.retype_new_password}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-start items-center gap-2">
            <button
              type="submit" // Button to submit the form
              className="bg-gradient-2 text-white py-1 px-2.5 text-xs font-bold cursor-pointer border-none outline-none rounded-md w-[100px] mx-auto my-5"
            >
              Cambiar {/* Button text */}
            </button>
            <button
              type="button" // Button to cancel the form
              onClick={() => {
                formik.setValues(initialValues);
                handleClose();
              }}
              className="bg-gradient-to-r from-[#ed4141] to-[#9c3903] text-white py-1 px-2.5 text-xs font-bold cursor-pointer border-none outline-none rounded-md w-[100px] mx-auto my-5"
            >
              Cancel {/* Button text */}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ChangePasswordPop;
