import React from "react";
import Navbar from "../../../../components/common/Navbar";
import Sidebar from "../../../../components/common/Sidebar";
import UserMain from "./component/UserMain";
// import ProfileMain from "./component/ProfileMain";

const SingleUser = () => {
  return (
    <div >
  
        <UserMain />
      </div>
  );
};

export default SingleUser;
