import React from "react";

const Validate = () => {
  return (
    <div className="h-screen w-[100%] flex justify-center items-center text-3xl font-bold">
      Validating
      <span className="dots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    </div>
  );
};

export default Validate;
