// /src/auth/login/route.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import ViewVisitors from "./viewVisitors/ViewVisitors";
import SingleUser from "./singlevUser/SingleUser";
const VisitorsRoutes = () => {
  return (
    <Routes>
      <Route path="user" element={<ViewVisitors />} />
      <Route path="user/:id" element={<SingleUser />} />
    </Routes>
  );
};

export default VisitorsRoutes;
