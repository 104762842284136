// /src/dashboard/admin/route.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardAdmin from "./home/DashboardAdmin";
const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="home" element={<DashboardAdmin />} />
    </Routes>
  );
};

export default DashboardRoutes;
