import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../../../components/common/Loader";
// import { fetchUsers } from "../../../visitorsApi/VisitorsApi";
import { toast, ToastContainer } from "react-toastify";
// import { notify } from "../../../../../utils/Notify";
// import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import {
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { PiExport } from "react-icons/pi";
import { FaRegEdit } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import {
  deleteProductDataApi,
  getCategoriesApi,
  getCategoryApi,
  getCategoryProductApi,
} from "../productApi/productApi";
import { baseUrl } from "../../../../../config/BaseUrl";
import CoustomModal from "../../../../../components/Model/Model";
import { notify } from "../../../../../utils/Notify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const ProductTable = () => {
  const navigation = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [loading, setLoading] = useState(false); // for table loader

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [categories, setCategories] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(10);
  const [filterModel, setFilterModel] = useState({ items: [] }); // For capturing filter state
  const [sortBy, setSortBy] = useState(""); // For capturing filter state
  const [sortOrder, setSortOrder] = useState(""); // For capturing filter state

  const [selectedCategory, setSelectedCategory] = useState({
    _id: "",
    name: "all",
  });

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleModelClose = () => {
    setOpenDeleteModel(false);
  };

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    search: "",
    fields: ["name", "description"],
    sortBy: ["name", "created_at"],
    sortOrder: ["asc", "desc"],
  });

  const [usersData, setUsersData] = useState([]);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const categoriesAPI = async (keyword) => {
    const result = await getCategoriesApi(keyword);
    setSelectedCategory(result?.data?.categories[0]);
    setCategories(result?.data?.categories);
  };

  // Function to handle pagination change
  const handlePaginationChange = async (paginationModel) => {
    setFormData((prev) => ({
      ...prev,
      page: paginationModel.page + 1, // MUI page is 0-indexed, so add 1
      size: paginationModel.pageSize,
    }));
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  // Function to handle sorting change
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setFormData((prev) => ({
        ...prev,
        sortBy: sortModel.map((sort) => sort.field), // Update sortBy fields
        sortOrder: sortModel.map((sort) => sort.sort), // Update sortOrder (asc/desc)
      }));
    }
  };

  const handalTableHeaderData = async () => {
    try {
      const result = await getCategoryApi(selectedCategory._id);
      const formattedData = result.data.category.productFields.map((item) => ({
        field: item.replace(/_/g, " "),
        fieldValue: item.replace(/\s+/g, "_"),
      }));
      const dynamicColumns = formattedData.map((item) => {
        return {
          field: item.fieldValue,
          headerName: item.field.charAt(0).toUpperCase() + item.field.slice(1), // Capitalize the first letter
          width: 150,
        };
      });
      setColumns(dynamicColumns);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProductData = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteProductDataApi(data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handelTableData();
    setOpenDeleteModel(false);
  };

  const handelTableData = async () => {
    try {
      const result = await getCategoryProductApi(
        selectedCategory._id,
        formData
      );
      const userData = result.data.products.map((item) => {
        return {
          ...item.details,
          _id: item._id,
          Product: item.name,
        };
      });
      setRowCount(result.data.totalCount);
      // setPageSize(result.data.totalPages);
      // setPage(result.data.currentPage);
      setUsersData(userData);
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>result", result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setOpenLoader(true);
    categoriesAPI();
    setOpenLoader(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    handalTableHeaderData();
    handelTableData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory._id]);

  useEffect(() => {
    handelTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.sortOrder, formData.page, formData.size]);

  return (
    <>
      <div className="flex justify-start items-center  py-5">
        <ToastContainer />
        <Loader open={openLoader} handleClose={handleCloseLoader} />
        <div className="flex justify-between items-center w-full">
          <div className="flex">
          <div className="flex justify-start items-start flex-col">
            <FormControl sx={{ width: "200px", height: "40px" }} fullWidth>
              {/* <InputLabel id="demo-multiple-name-label">Category</InputLabel> */}
              <Select
                value={selectedCategory.name}
                onChange={handleChangeCategory}
                MenuProps={MenuProps}
                IconComponent={MdKeyboardArrowDown}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Placeholder</em>;
                  }

                  return selected;
                }}
                sx={{
                  backgroundColor: "#E5F1F9",
                  border: "1px solid #EAEAEA",
                  minHeight: "40px",

                  "& .MuiSelect-select": {
                    padding: "8.5px 32px 8.5px 14px",
                    fontWeight: 600,
                    fontSize: "11.6px",
                    lineHeight: "normal",
                    color: "#1B216A",

                    display: "flex",
                    alignItems: "center",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #1B216A",
                  },

                  "&:hovers .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #1B216A",
                  },

                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #1B216A",
                  },

                  "& .MuiSelect-icon": {
                    color: "#000000",
                    fontSize: "1.5rem",
                  },
                }}
              >
                {/* <MenuItem disabled sx={{}}>
                      {" "}
                      <em>PlaceHolder</em>
                    </MenuItem> */}
                {categories?.map((category) => (
                  <MenuItem
                    key={category.name}
                    value={category}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedRows.length > 0 && (
              <div className="flex justify-end items-end ml-5">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 40,
                    height: 40,
                    backgroundColor: "#E5F1F9",
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => handleDeleteClick()}
                  >
                    <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
                  </IconButton>
                </Paper>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center ">
            <button
              className="text-white px-5 bg-gradient-2 h-8 rounded-md flex justify-center items-center gap-1"
              onClick={() => {
                const link = document.createElement("a");
                link.href = `${baseUrl}/api/products/export?format=csv`;
                link.setAttribute("download", "products.csv"); // Optional: If you want to specify a filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up the DOM
              }}
            >
              <PiExport className="rotate-90" /> Export
            </button>
          </div>
        </div>
      </div>
      <div>
        <Paper
          sx={{
            width: "100%",
            height: "27.5rem",
          }}
        >
          <DataGrid
            rows={usersData}
            columns={columns}
            getRowId={(row) => row._id}
            // page={page}
            // pageSize={pageSize}
            paginationMode="server"
            rowCount={rowCount}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPaginationModelChange={handlePaginationChange} // Pagination event
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={selectedRows}
            onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
            pageSizeOptions={[5, 10, 25, 50, 100]}
            filterModel={filterModel} // Attach the filter model to DataGrid
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            } // Update filter model on filter change
            sortingMode="server" // Server-side sorting
            onSortModelChange={handleSortChange} // Sorting event
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
      <CoustomModal
        title={"Delete File History"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteProductData}
      >
        <p className=" text-red-800 my-10 ">
          Do you want to delete this file history
        </p>
      </CoustomModal>
    </>
  );
};

export default ProductTable;
