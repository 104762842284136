// /src/auth/login/route.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./login/Login"; // Import your login component here
import ResetPassword from "./resetPassword/ResetPassword";
import ForgotPassword from "./forgotPassword/ForgotPassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="finderscope/v1/admin/auth/reset/password/:id"
        element={<ResetPassword />}
      />
      <Route
        path="finderscope/v1/admin/auth/forgot/password"
        element={<ForgotPassword />}
      />
    </Routes>
  );
};

export default AuthRoutes;
