import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./api/LoginApi";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../utils/Notify";
import Loader from "../../../../components/common/Loader";
import { useFormik } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import * as Yup from "yup";

/*****
 @object: vailidetion schema
 @description: This schema are vailidet user inputs email and password  
 *****/
const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email!") // Validates the format of the email
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must include a valid domain (e.g., .com, .in)"
    )
    .min(5, "Email must be at least 5 characters long.") // Ensures minimum length
    .max(50, "Email cannot be longer than 50 characters.") // Ensures maximum length
    .required("Please enter your email!"),
  password: Yup.string()
    .required("Please enter your password!") // Ensures the password is not empty
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,30}$/,
      "Password must be between 8 and 30 characters, and contain a mix of uppercase, lowercase and numbers."
    ),
});

const Login = () => {
  const navigation = useNavigate();
  const refresh =
    typeof window !== "undefined" ? localStorage.getItem("refresh") : null;
  useEffect(() => {
    localStorage.removeItem("login");
    if (refresh) {
      navigation("/finderscope/v1/admin/dashboard/home");
    }
  }, []);
  const [openLoader, setOpenLoader] = useState(false);
  const [show, setShow] = useState(false); // this state are use for view password feature

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: schema,
    onSubmit: async ({ email, password }) => {
      handleOpenLoader();
      login({ email, password }) //login api
        .then((response) => response.json()) // Convert the response to JSON
        .then((data) => {
          if (data.success === true) {
            handleCloseLoader?.();
            notify(data.message[0], "success");
            localStorage.setItem("access", data.token);
            localStorage.setItem("refresh", data.refreshToken);
            navigation("/finderscope/v1/admin/dashboard/home");
          } else if (data.success === false) {
            handleCloseLoader?.();
            notify(data.message[0], "error");
          }
        }) // Handle the response data
        .catch((error) => console.error("Error:", error));
    },
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div className="w-[100%] h-[100vh] bg-[#E9E9E9] relative">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="w-full h-full bg-gradient-4 flex justify-start items-start p-10">
        <div className="flex justify-start flex-col">
          <img src="images/logo-finder-scope.svg" alt="Finder Scope Logo" width={210} height={100} />
          {/* <h1 className="text-white text-[80px]">Finderscope</h1> */}
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 text-center bg-white rounded-md">
          <form onSubmit={handleSubmit}>
            <h1 className="text-4xl font-semibold font-inter mb-5">Account Sign In</h1>
            <div className="flex flex-col justify-start items-start gap-1">
              <label
                htmlFor="email"
                className="text-[#0066FF] text-sm font-bold"
              >
                Email Address<span className="text-[#e03b3b]">*</span>
              </label>
              <input
                id="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                name="email"
                className={`w-[400px] outline-[#0066FF] pl-2.5 h-[50px] border-[2px] border-[#8692A6] ${
                  errors.email && touched.email && "!border-red-500"
                } rounded-md`}
              />
              {errors.email && touched.email && (
                <span className="text-red-500 w-full text-left text-wrap pt-1 text-xs block">
                  {errors.email}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-start items-start gap-1 mt-5">
              <label
                htmlFor="password"
                className="text-[#0066FF] text-sm font-bold"
              >
                Password<span className="text-[#e03b3b]">*</span>
              </label>
              <div className="relative w-[400px] flex justify-end items-center">
                <input
                  id="password"
                  type={!show ? "password" : "text"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  className={`w-full outline-[#0066FF] pl-2.5 h-[50px] border-[2px] border-[#8692A6] ${
                    errors.password && touched.password && "!border-red-500"
                  } rounded-md`}
                />
                {!show ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShow(true)}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute text-[#ACACAC] right-3 z-1 cursor-pointer"
                    size={20}
                    onClick={() => setShow(false)}
                  />
                )}
              </div>
              {errors.password && touched.password && (
                <span className="text-red-500 w-[400px] text-left  pt-1 text-xs block">
                  {errors.password}
                </span>
              )}
            </div>
            <p
              onClick={() => {
                handleOpenLoader();
                navigation("/finderscope/v1/admin/auth/forgot/password");
              }}
              className="text-right text-xs text-[#00AEFF] mt-1 cursor-pointer"
            >
              Forgot Password?
            </p>
            <button
              type="submit"
              className="text-white px-8 mt-7 bg-gradient-2 h-10 rounded-md"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
