import axios from "axios";
import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
  putDataAPI,
} from "../../../../../utils/fetchData";
import { baseUrl } from "../../../../../config/BaseUrl";

// export const getChathistoryApi = async (id) => {
//   const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
//   const result = await getDataAPI(`admin/users/${id}/conversations`, token);
//   return result;
// };

export const getChathistoryApi = async (id, data) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const result = await postDataAPI(
    `admin/users/${id}/conversations`,
    data,
    token
  );
  return result;
};

export const exportCategorieslistApi = async (format) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const result = await getDataAPI(
    `categories/export?format=${format ?? ""}`,
    token
  );
  return result;
};

export const createCategorieslistApi = async (data) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const result = await postDataAPI(`categories`, data, token);
  return result;
};

export const editCategorieslistApi = async (id, data) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const result = await putDataAPI(`categories/${id}`, data, token);
  return result;
};

// export const deleteCategorieslistApi = async (id) => {
//     const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
//     const result = await deleteDataAPI(`categories/${id}`, token)
//     return result
// };

export const deleteUserChatHistoryApi = async (id, data) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const res = await axios.delete(`${baseUrl}/api/admin/users/${id}/conversations`, {
    headers: { Authorization: token },
    data: data,
  });
  return res;
};
