import React from "react";
import Sidebar from "../common/Sidebar";
import Navbar from "../common/Navbar";

const Layout = ({ children }) => {
  return (
    <div className="flex gap-2 bg-[#e1e3e6]   ">
      <Sidebar />
      <div className="flex-1 flex flex-col w-[83vw] h-[99vh]   ">
        <Navbar />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
