import React from "react";
import { Routes, Route } from "react-router-dom";
import ViewProfile from "./viewProfile/ViewProfile";
const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="view" element={<ViewProfile />} />
    </Routes>
  );
};

export default ProfileRoutes;
