import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RecentActivityTable = ({ data }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const navigation = useNavigate();
  const columns = [
    // { field: "sn", headerName: "SN", flex: 1 },
    { field: "name", headerName: "Username", flex: 1 },
    { field: "conversation_count", headerName: "No of Sessions", flex: 1 },
    { field: "totalTokens", headerName: "Total Token Used", flex: 1 },
    { field: "message_count", headerName: "Total Q&A", flex: 1 },
    { field: "latest_message", headerName: "Time Started", flex: 1 },
    {
      field: "",
      headerName: "Action",
      flex: 1, // Take minimal space
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <button
            onClick={() =>
              navigation(
                `/finderscope/v1/admin/chat-history/conversation/${params.row.most_recent_conversation}`
              )
            }
            className="text-white flex justify-center items-center h-7 px-4 bg-gradient-2 w-[6rem] rounded-md"
          >
            View Chat
          </button>
        </Box>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="flex flex-col gap-2 mb-2.5">
        <p className="text-[#0B3CAB] text-1xl font-bold">Recent Activity</p>
        {/* <input
          type="date"
          className="w-[120px] no-calendar-icon border-[2px] border-gray-300 py-1.5 text-center outline-none"
        /> */}
      </div>
      <Paper sx={{ width: "100%", height: "23.5rem" }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row._id}
          disableColumnMenu
          disableColumnSelector
          disableColumnSorting
          hideFooter
          // loading={loading}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sx={{
            border: 0,
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
        />
      </Paper>
    </>
  );
};

export default RecentActivityTable;
