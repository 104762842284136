import React, { useEffect, useState } from "react";
import { resetPassword, verifyTokenId } from "./api/resetPasswordApi";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../utils/Notify";
import Loader from "../../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";

//import finderlogo from "../../../assets/finderlogo.png";

const ResetPassword = () => {
  const navigation = useNavigate();

  const [openLoader, setOpenLoader] = useState(false);
  const { id } = useParams();
  const [userId, setUserId] = useState("");

  const verifyToken = async (token) => {
    handleOpenLoader?.();
    verifyTokenId(token) // verify token api
      .then((response) => response.json()) // Convert the response to JSON
      .then((data) => {
        if (data.success) {
          notify(data.message[0], "success");
          setUserId(data.admin._id);
          handleCloseLoader?.();
        }
      }) // Handle the response data
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    // Replace with the actual JWT token you get from params.
    const token = Array.isArray(id) ? id[0] : id; // If it's an array, take the first element

    if (token) {
      verifyToken(token); // call the verify token api
    }
  }, []);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  useEffect(() => {
    // Open the loader
    handleOpenLoader();

    // Set a timeout to close the loader after 2 seconds
    const timer = setTimeout(() => {
      handleCloseLoader();
    }, 2000);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const [formData, setFormData] = useState({
    _id: "",
    new_password: "",
    retype_new_password: "",
  });

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      _id: userId,
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    handleOpenLoader();
    resetPassword(formData) //reset password api
      .then((response) => response.json()) // Convert the response to JSON
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader?.();
          notify(data.message[0], "success");
          navigation("/");
        } else if (data.success === false) {
          handleCloseLoader?.();
          notify(data.message[0], "error");
        }
      }) // Handle the response data
      .catch((error) => console.error("Error:", error));
  };

  const isPasswordMismatch =
    formData.new_password &&
    formData.retype_new_password &&
    formData.new_password !== formData.retype_new_password;

  return (
    <div className="w-[100%] h-[100vh] bg-[#E9E9E9] relative">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <div className="w-[70%] h-[100%] bg-[#1B216A] flex justify-start items-end p-10">
        <div className="flex justify-start flex-col">
          {/* <img
            className="h-[80px] w-[120px] bg-white"
            src={finderlogo}
            alt="company_logo"
          /> */}
          <h1 className="text-white text-[80px]">Finderscope</h1>
        </div>
        <div className="absolute right-[22%] p-10 text-center top-[25%] bg-white rounded-md">
          <form onSubmit={handleResetPassword}>
            <h1 className="text-2xl font-semibold mb-5">Reset Password</h1>
            <div className="flex flex-col justify-start items-start gap-1 ">
              <label
                htmlFor="new_password"
                className="text-[#1B216A] text-sm font-bold"
              >
                Create Password
              </label>
              <input
                id="new_password"
                required
                type="password"
                onChange={handleInput}
                name="new_password"
                className="w-[400px] outline-[#1B216A] pl-2.5 h-[50px] border-[2px] border-[#8692A6] rounded-md"
              />
            </div>
            <div className="flex flex-col justify-start items-start gap-1 mt-5">
              <label
                htmlFor="retype_new_password"
                className="text-[#1B216A] text-sm font-bold"
              >
                Confirm Password
              </label>
              <input
                id="retype_new_password"
                required
                type="text"
                onChange={handleInput}
                name="retype_new_password"
                className={`w-[400px] outline-[#1B216A] pl-2.5 h-[50px] border-[2px] rounded-md ${
                  isPasswordMismatch ? "border-red-500" : "border-gray-400"
                }`}
              />
            </div>
            <button
              type="submit"
              className="text-white w-[400px] mt-7 bg-[#1B216A] h-10 rounded-md"
            >
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
