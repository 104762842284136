import React, { useCallback, useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../finderscope/v1/profie/viewProfile/api/profileApi";
import Loader from "./Loader";

const Navbar = () => {
  const [openLoader, setOpenLoader] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const navigation = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [count, setCount] = useState(0);
  const [adminData, setAdminData] = useState([]);

  const handleLogout = () => {
    handleOpenLoader();
    navigation("/");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };

  const handleGetProfile = useCallback(() => {
    handleOpenLoader(); // Open loader
    getProfile()
      .then((response) => response.json())
      .then((data) => {
        handleCloseLoader(); // Close loader
        setAdminData(data.admin); // Set admin data from the response
      })
      .catch((err) => {
        handleCloseLoader(); // Ensure loader is closed on error
        console.error(err);
      });
  }, [setAdminData]); // Only depend on setAdminData if it's defined in state

  // Use useEffect to call handleGetProfile when the component mounts
  useEffect(() => {
    handleGetProfile(); // Load the profile data when the component mounts
  }, [handleGetProfile]);

  return (
    <div className=" flex justify-end items-center gap-5 bg-gradient-1 p-4 ">
      <Loader open={openLoader} handleCloseLoader={handleCloseLoader} />
      {/* <FaRegBell className="text-2xl" /> */}
      <div
        className="flex justify-center items-center gap-2 cursor-pointer relative"
        onClick={() => {
          if (count === 0) {
            setShowOptions(true);
            setCount(1);
          } else {
            setShowOptions(false);
            setCount(0);
          }
        }}
      >
        <CgProfile className="text-2xl text-[#71839B]" />
        <div className="flex flex-col">
          <span className="font-normal text-sm text-[#324054]">
            {adminData?.first_name} {adminData?.last_name}
          </span>
          <span className="font-normal text-xs text-[#71839B]">
            {adminData?.email}
          </span>
        </div>
        <IoIosArrowDown className="ml-4" />
        {showOptions ? (
          <div className="absolute top-[110%] right-[-15px] z-50 bg-white p-3 flex flex-col gap-2">
            <p
              className="flex justify-start items-center gap-2 text-[#1B216A] text-sm font-medium"
              onClick={() => {
                navigation("/finderscope/v1/admin/profile/view");
              }}
            >
              <CgProfile />
              My Profile
            </p>
            <hr className="border-[#E7E7E7] border-[1px]" />
            <p
              className="flex justify-start items-center gap-2 text-[#1B216A] text-sm font-medium"
              onClick={handleLogout}
            >
              <MdLogout />
              Logout
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
