import React from "react";
import Sidebar from "../../../../components/common/Sidebar";
import Navbar from "../../../../components/common/Navbar";
import UploadsMain from "./components/UploadsMain";

const ViewUploads = () => {
  return (
    <div>
      <UploadsMain />
    </div>
  );
};

export default ViewUploads;
