import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/common/Loader";
import { ToastContainer } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Paper, Select } from "@mui/material";
import { VscCloudDownload } from "react-icons/vsc";
import {
  getCategoriesApi,
  getErrorLogsApi,
  scanCsvOrExcelApi,
  selectCheckboxApi,
  uploadCsvOrExcelApi,
  uploadNewFileApi,
} from "../uploadsApi/uploadsApi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { WiCloudUp } from "react-icons/wi";
import { toast } from "react-toastify";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { notify } from "../../../../../utils/Notify";
import { baseUrl } from "../../../../../config/BaseUrl";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const FileProductSelect = () => {
  const navigation = useNavigate();
  const [screenActive, setScreenActive] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [loading, setLoading] = useState(false); // for table loader
  const [categories, setCategories] = useState([]);
  const [fileId, setFileId] = useState();
  const [selectedCategory, setSelectedCategory] = useState({
    _id: "",
    name: "all",
  });
  const [columns, setColumns] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [errorPage, setErrorPage] = React.useState(0);
  const [errorPageSize, setErrorPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [errorRowCount, setErrorRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] }); // For capturing filter state
  const [errorFilterModel, setErrorFilterModel] = useState({ items: [] }); // For capturing filter state
  const [usersData, setUsersData] = useState([]);
  const [errorLogData, setErrorLogData] = useState([]);
  const [sortBy, setSortBy] = useState(""); // For capturing filter state
  const [sortOrder, setSortOrder] = useState(""); // For capturing filter state
  const [selectedRows, setSelectedRows] = useState([]);
  const [uniqueData, setUniqueData] = useState(0);
  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    searchUsers: "",
    searchDate: "",
    fields: ["name", "email", "created_at"],
    sortBy: ["name", "created_at"],
    sortOrder: ["asc", "desc"],
  }); // this state is use only for error logs

  const logColumn = [
    { field: "product_name", headerName: "Product Name", width: 170 },
    { field: "product_asin", headerName: "Product ASIN", width: 130 },
    { field: "file_name", headerName: "File Name", width: 230 },
    { field: "message", headerName: "Description", width: 290 },
    { field: "timestamp", headerName: "Registered Date", width: 100 },
  ];

  const categoriesAPI = async (keyword) => {
    const result = await getCategoriesApi(keyword);
    setSelectedCategory(result?.data?.categories[0]);
    setCategories(result?.data?.categories);
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDownloadSample = async () => {
    if (selectedCategory._id !== "") {
      const link = document.createElement("a");
      link.href = `${baseUrl}/api/files/download/csv/${selectedCategory._id}`;
      link.setAttribute("download", `Category-${selectedCategory.name}_Products.csv`); // Optional: If you want to specify a filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
    }else {
      toast.error("Please select category");
    }
  };

  const handleSelectionChange = async (newSelection) => {
    const matchedIds = [];
    const unmatchedIds = [];

    // Filter data
    usersData.forEach((item) => {
      if (newSelection.includes(item._id)) {
        matchedIds.push(item._id);
      } else {
        unmatchedIds.push(item._id);
      }
    });

    const data = {
      matchedIds,
      unmatchedIds,
    };
    await selectCheckboxApi(data);
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  // Function to handle pagination change
  const handlePaginationChange = async (paginationModel) => {
    const scanResult = await scanCsvOrExcelApi(
      selectedCategory._id,
      paginationModel.pageSize,
      paginationModel.page + 1,
      sortBy,
      sortOrder
    );
    setRowCount(scanResult.data.totaltempProduct);
    setPageSize(paginationModel.pageSize);
    setPage(paginationModel.page);
    setUsersData(scanResult.data.comparisonResultArr);
  };

  // Function to handle error logs pagination change
  const handleErrorPaginationChange = async (paginationModel) => {
    setFormData((prev) => ({
      ...prev,
      page: paginationModel.page + 1, // MUI page is 0-indexed, so add 1
      size: paginationModel.pageSize,
    }));
    setErrorPageSize(paginationModel.pageSize)
    setErrorPage(paginationModel.page)
  };

  // Function to handle sorting change
  const handleSortChange = async (sortModel) => {
    if (sortModel.length > 0) {
      const scanResult = await scanCsvOrExcelApi(
        selectedCategory._id,
        pageSize,
        page + 1,
        sortBy,
        sortOrder
      );
      setRowCount(scanResult.data.totaltempProduct);
      setPage(scanResult.data.currentPage);
      setUsersData(scanResult.data.comparisonResultArr);
    }
  };

  // Function to handle sorting change
  const handleErrorSortChange = async (sortModel) => {
    if (sortModel.length > 0) {
      setFormData((prev) => ({
        ...prev,
        sortBy: sortModel.map((sort) => sort.field), // Update sortBy fields
        sortOrder: sortModel.map((sort) => sort.sort), // Update sortOrder (asc/desc)
      }));
    }
  };

  const handleFileChange = async (e) => {
    if (selectedCategory._id !== "") {
      const file = e.target.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        setOpenLoader(true);

        try {
          // Upload the file
          const uploadResult = await uploadCsvOrExcelApi(
            selectedCategory._id,
            formData
          );
          const formattedData = uploadResult.data.fields[
            selectedCategory._id
          ].map((item) => ({
            field: item.replace(/_/g, " "),
            fieldValue: item.replace(/\s+/g, "_"),
          }));

          const dynamicColumns = formattedData.map((item) => {
            return {
              field: item.fieldValue,
              headerName:
                item.field.charAt(0).toUpperCase() + item.field.slice(1), // Capitalize the first letter
              width: 150,
              renderCell: (params) => {
                if (typeof params.value === "object" && params.value !== null) {
                  const { oldValue, newValue, status } = params.value;
                  const { status: rowSatus } = params.row;

                  return (
                    <div
                      title={oldValue}
                      style={{
                        color: rowSatus
                          ? "red"
                          : status === 0
                          ? "black"
                          : "green",
                      }}
                    >
                      {newValue}
                    </div>
                  );
                }

                // If it's not an object, just render the value
                return params.value;
              },
            };
          });
          setColumns(dynamicColumns);
          toast.success(uploadResult.data.message);
          setFileId(uploadResult.data.fileId);
          setScreenActive(1);

          // Scan the uploaded data
          setLoading(true);
          const scanResult = await scanCsvOrExcelApi(selectedCategory._id);
          setRowCount(scanResult.data.totaltempProduct);
          setUniqueData(scanResult.data.uniqueData);
          setUsersData(scanResult.data.comparisonResultArr);
          setLoading(false);
        } catch (error) {
          // Handle any errors during the file upload or scan process
          console.error(error);
          toast.error(
            error?.response?.data?.message ||
              "An error occurred. Please try again."
          );
          setLoading(false);
        } finally {
          setOpenLoader(false);
        }
      }
    } else {
      toast.error("Please select a category");
    }
  };

  const handleUploadNewFileButton = async () => {
    setUsersData([]);
    setColumns([]);
    setScreenActive(0);
  };

  const handleUploadNewFile = async () => {
    if (selectedCategory._id !== "") {
      setOpenLoader(true);
      try {
        const data = {
          fileId: fileId,
        };
        const uploadResult = await uploadNewFileApi(selectedCategory._id, data);

        toast.success(uploadResult.data.message);
        setUsersData([]);
        setColumns([]);
        handleGetLogsFileList();
        setScreenActive(2);
      } catch (error) {
        toast.error(
          error?.response?.data?.message ||
            "An error occurred. Please try again."
        );
      } finally {
        setOpenLoader(false);
      }
    } else {
      toast.error("Please select category");
    }
  };

  const handleGetLogsFileList = async () => {
    setLoading(true);
    try {
      const result = await getErrorLogsApi(formData);
      const rowsWithSN = result.data.errorLogs.map((row, index) => ({
        ...row,
        sn: index + 1, // Add serial number starting from 1
      }));

      setErrorLogData(rowsWithSN);
      setErrorRowCount(result.data.totalCount);
      // setPageSize(result.data.totalPages);
      // setPage(result.data.currentPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setOpenLoader(true);
    categoriesAPI();
    setOpenLoader(false);
  }, []);

  useEffect(() => {
    handleGetLogsFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.sortOrder, formData.page, formData.size]);

  return (
    <>
      <div className="flex justify-start items-center mb-2.5 py-5">
        <ToastContainer />
        <Loader open={openLoader} />
        <div className="flex justify-between items-center w-full">
          {screenActive === 0 && (
            <>
              <div className="flex justify-start items-start flex-col">
                {/* <label
              htmlFor="category"
              className="text-[#0B3CAB] text-sm font-bold font-sans mb-2.5"
            >
              Category
            </label> */}
                <FormControl sx={{ width: "200px", height: "40px" }} fullWidth>
                  {/* <InputLabel id="demo-multiple-name-label">Category</InputLabel> */}
                  <Select
                    value={selectedCategory.name}
                    onChange={handleChangeCategory}
                    MenuProps={MenuProps}
                    IconComponent={MdKeyboardArrowDown}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Placeholder</em>;
                      }

                      return selected;
                    }}
                    sx={{
                      backgroundColor: "#E5F1F9",
                      border: "1px solid #EAEAEA",
                      minHeight: "40px",

                      "& .MuiSelect-select": {
                        padding: "8.5px 32px 8.5px 14px",
                        fontWeight: 600,
                        fontSize: "11.6px",
                        lineHeight: "normal",
                        color: "#1B216A",

                        display: "flex",
                        alignItems: "center",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1B216A",
                      },

                      "&:hovers .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1B216A",
                      },

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1B216A",
                      },

                      "& .MuiSelect-icon": {
                        color: "#000000",
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    {/* <MenuItem disabled sx={{}}>
                      {" "}
                      <em>PlaceHolder</em>
                    </MenuItem> */}
                    {categories?.map((category) => (
                      <MenuItem
                        key={category.name}
                        value={category}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex justify-end items-center pr-10">
                <Button
                  variant="outlined"
                  startIcon={<VscCloudDownload />}
                  onClick={() => handleDownloadSample("csv")}
                >
                  <span className=" underline">Sample CSV File</span>
                </Button>
              </div>
            </>
          )}
          {screenActive === 1 && (
            <>
              <div className="flex justify-between w-full bg-[#F5F5F5] h-14 items-center pr-10">
                <p className="pl-4 ml-2">
                  {uniqueData > 0 ? uniqueData : "No"} Unique Products have been
                  identified
                </p>
                <button
                  className="text-white px-5 bg-gradient-2 h-8 rounded-md "
                  onClick={handleUploadNewFile}
                >
                  Update
                </button>
              </div>
            </>
          )}
          {screenActive === 2 && (
            <>
              <div className="flex justify-between w-full bg-[#F5F5F5] items-center h-14 pr-10">
                <h3 className="pl-4 text-xl font-sans font-semibold text-blue-500">Error Logs</h3>
                <div className="flex justify-end h-full items-center">
                  <button
                    className="text-white px-5 bg-gradient-2 h-8 rounded-md mr-3"
                    onClick={() =>
                      navigation(
                        "/finderscope/v1/admin/data-management/products"
                      )
                    }
                  >
                    See Products
                  </button>
                  <button
                    className="text-white px-5 bg-gradient-2 h-8 rounded-md "
                    onClick={handleUploadNewFileButton}
                  >
                    Upload New File
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center w-full h-[26rem] bg-[#F5F5F5] items-center">
        <div className="w-full h-full">
          {screenActive === 0 && (
            <>
              <input
                type="file"
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="file"
                className="hidden"
                onChange={handleFileChange}
              />
              <label
                htmlFor="file"
                className={`w-full min-h-[25.5rem] border-[#ccccccc26] p-3 border border-dashed flex flex-col items-center justify-center cursor-pointer ${
                  dragging ? "bg-blue-500" : "bg-transparent"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <WiCloudUp
                  className={`text-[15rem]  ${
                    dragging ? "text-[#ffffff]" : "text-[#00000066]"
                  }`}
                />
                <span
                  className={`${
                    dragging ? "text-[#ffffff] font-bold" : "text-black"
                  }`}
                >
                  Select a file or drag and drop here
                </span>
                <span
                  className={`${
                    dragging ? "text-[#ffffff]" : "text-[#00000066]"
                  }`}
                >
                  CSV, file size no more than 10MB
                </span>
                <label
                  htmlFor="file"
                  className={`border  py-5 px-7 mt-5 rounded-md cursor-pointer ${
                    dragging
                      ? "text-[#ffffff]"
                      : "border-[#0F91D2B2] text-[#0F91D2B2]"
                  }`}
                >
                  SELECT FILE
                </label>
              </label>
            </>
          )}
          {screenActive === 1 && (
            <>
              <Paper sx={{ width: "100%", height: "100%" }}>
                <DataGrid
                  rows={usersData}
                  columns={columns}
                  getRowId={(row) => row._id}
                  // page={page}
                  // pageSize={pageSize}
                  paginationMode="server"
                  rowCount={rowCount}
                  paginationModel={{page: page, pageSize: pageSize}}
                  onPaginationModelChange={handlePaginationChange} // Pagination event
                  loading={loading}
                  checkboxSelection
                  disableRowSelectionOnClick
                  selectionModel={selectedRows}
                  // onSelectionModelChange={handleSelectionChange} // Handle checkbox selection
                  onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  filterModel={filterModel} // Attach the filter model to DataGrid
                  onFilterModelChange={(newFilterModel) =>
                    setFilterModel(newFilterModel)
                  } // Update filter model on filter change
                  disableColumnMenu
                  sortingMode="server" // Server-side sorting
                  onSortModelChange={handleSortChange} // Sorting event
                  // isRowSelectable={(params) => !params.row.status}
                  sx={{
                    border: 0,
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                      {
                        outline: "none",
                      },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: "none",
                      },
                  }}
                />
              </Paper>
            </>
          )}
          {screenActive === 2 && (
            <>
              <Paper sx={{ width: "100%", height: "100%" }}>
                <DataGrid
                  rows={errorLogData}
                  columns={logColumn}
                  getRowId={(row) => row._id}
                  page={errorPage}
                  pageSize={errorPageSize}
                  paginationMode="server"
                  rowCount={errorRowCount}
                  paginationModel={{page: errorPage, pageSize: errorPageSize}}
                  onPaginationModelChange={handleErrorPaginationChange} // Pagination event
                  loading={loading}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  filterModel={errorFilterModel} // Attach the filter model to DataGrid
                  onFilterModelChange={(newFilterModel) =>
                    setErrorFilterModel(newFilterModel)
                  } // Update filter model on filter change
                  disableColumnMenu
                  sortingMode="server" // Server-side sorting
                  onSortModelChange={handleErrorSortChange} // Sorting event
                  sx={{
                    border: 0,
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                      {
                        outline: "none",
                      },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: "none",
                      },
                  }}
                />
              </Paper>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileProductSelect;
