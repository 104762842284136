import React from "react"
import ProfileMain from "./component/ProfileMain";

const ViewProfile = () => {
  return (
    <div>
   
        <ProfileMain />
    </div>
  );
};

export default ViewProfile;
