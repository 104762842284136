// /src/routes/routes.jsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoutes from "../finderscope/v1/auth/route"; // Import login routes
import ProfileRoutes from "../finderscope/v1/profie/route";
import DashboardRoutes from "../finderscope/v1/dashboard/route";
import VisitorsRoutes from "../finderscope/v1/visitors/route";
import DataManagementRoutes from "../finderscope/v1/DataManagement/route";
import Layout from "../components/layout/Layout"; // Import Layout component

const AppRoutes = () => {
  return (
    <Routes>
      {/* Auth routes don't need the layout */}
      <Route path="/*" element={<AuthRoutes />} />

      {/* Routes requiring layout with Sidebar and Navbar */}
      <Route
        path="/finderscope/v1/admin/profile/*"
        element={<Layout><ProfileRoutes /></Layout>}
      />
      <Route
        path="/finderscope/v1/admin/dashboard/*"
        element={<Layout><DashboardRoutes /></Layout>}
      />
      <Route
        path="/finderscope/v1/admin/visitors/*"
        element={<Layout><VisitorsRoutes /></Layout>}
      />
      <Route
        path="/finderscope/v1/admin/data-management/*"
        element={<Layout><DataManagementRoutes /></Layout>}
      />
    </Routes>
  );
};

export default AppRoutes;
