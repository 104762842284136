import React, { useEffect } from "react";
import UserMain from "./component/UserMain";
import Layout from "../../../../components/layout/Layout";
import { useHandleValidateRefresh } from "../../../../constants/RefreshValidate";
import Validate from "../../../../constants/Validate";
const SingleUser = () => {
  const isLoggedIn = !!localStorage.getItem("access");
  const handleValidateRefresh = useHandleValidateRefresh();
  useEffect(() => {
    handleValidateRefresh();
  }, []);
  return isLoggedIn ? (
    <Layout>
      <UserMain />
    </Layout>
  ) : (
    <Validate />
  );
};

export default SingleUser;
