// /src/auth/login/route.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import ViewCategories from "./Categories/viewCategories";
import ViewProducts from "./Products/viewProducts";
import ViewUploads from "./Uploads/viewUploads";
import FileUpload from "./Uploads/fileUpload";
const DataManagementRoutes = () => {
  return (
    <Routes>
      <Route path="categories" element={<ViewCategories />} />
      <Route path="products" element={<ViewProducts />} />
      <Route path="uploads" element={<ViewUploads />} />
      <Route path="upload" element={<FileUpload />} />
    </Routes>
  );
};

export default DataManagementRoutes;
