import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function MultiSelectValue({value, setValue}) {
  const [inputValue, setInputValue] = useState('');
  // const [value, setValue] = useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '60px', // Set the min-height to 60px
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '10px', // Optional: Adjust padding inside the input
    }),
  };

  return (
    <CreatableSelect
      className={`font-sans text-base`}
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setValue(newValue || [])}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value}
      styles={customStyles} 
    />
  );
}
