import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const Loader = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        // Only allow closing with handleCloseLoader, not by clicking outside or pressing 'Esc'
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none rounded-lg p-4">
        <div className="loader"></div>
      </Box>
    </Modal>
  );
};

export default Loader;
