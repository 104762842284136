import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/common/Loader";
import { notify } from "../../../../../utils/Notify";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { fetchUserbyId } from "../../visitorsApi/VisitorsApi";
import { Box, Divider, Grid, Grid2, Typography } from "@mui/material";
const UserMain = () => {
  const [openLoader, setOpenLoader] = useState(false);
  const { id } = useParams();

  console.log(id);
  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const isEditable = true;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    created_date: "",
    status: "",
  });

  const handleGetUser = () => {
    handleOpenLoader();
    fetchUserbyId(id)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader();
          setFormData(data.user);
        } else if (data.success === false) {
          handleCloseLoader();
          notify(data.message[0], "error");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  return (
    <div className="my-2.5 w-[100%] bg-white py-5 px-10 ">
      <ToastContainer />
      <Loader open={openLoader} handleClose={handleCloseLoader} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          mt: "12px",
          pb: "10px",
        }}
      >
        <img
          className="h-[200px] w-[180px] rounded-md"
          src="https://images.unsplash.com/photo-1542452376175-82b6fb643412?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cGVyc29ufGVufDB8fDB8fHww"
          alt="profile_img"
        />
        <Box
          sx={{
            gap: "10px",
            // mt: "12px",
            pb: "30px",
            border: "1px solid #D1D5DC",
            borderRadius: "5px",
            padding: "10px",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#000", fontWeight: "bold", fontSize: "1rem" }}
          >
            User Details
          </Typography>
          <Divider
            sx={{
              mt: "12px",
              mb: "12px",
              border: "1px solid #D1D5DC",
            }}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              width: "100%",
              mb: "20px",
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                variant="span"
                sx={{
                  color: "#949494",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                Name
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "1.23rem",
                }}
              >
                {formData.name}
              </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                variant="span"
                sx={{
                  color: "#949494",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                Email
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "1.23rem",
                }}
              >
                {formData.email}
              </Typography>
              </Box>
            
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                variant="span"
                sx={{
                  color: "#949494",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                Register Date
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "1.23rem",
                }}
              >
                {formData.created_date}
              </Typography>
              </Box>
            
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                variant="span"
                sx={{
                  color: "#949494",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                Status
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "1.23rem",
                }}
              >
                {formData.status}
              </Typography>
              </Box>
            
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <div className="flex justify-start items-start gap-10 mt-12 pb-10">
        <img
          className="h-[200px] w-[180px] rounded-md"
          src="https://images.unsplash.com/photo-1542452376175-82b6fb643412?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cGVyc29ufGVufDB8fDB8fHww"
          alt="profile_img"
        />
        <form className="flex flex-col gap-10">
          <div className="w-[100%]">
            <h2 className="text-md text-black font-bold">User Details</h2>
            <hr className="w-[100%]" />
            <div className="flex justify-center gap-[50px] items-center p-5  border-[1px] rounded-md border-[#D1D5DC]">
              <div className="flex flex-col justify-start items-start gap-1">
                <label
                  htmlFor="name"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Name
                </label>
                <input
                  id="name"
                  required
                  type="text"
                  name="name"
                  value={formData.name}
                  className={`w-[300px]  outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable} // Disable the input if not in edit mode
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1">
                <label
                  htmlFor="email"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Email
                </label>
                <input
                  id="email"
                  required
                  type="text"
                  name="email"
                  value={formData.email}
                  className={`w-[300px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="flex justify-center gap-[50px] items-center p-5 border-[1px] rounded-md border-[#D1D5DC] mt-5">
              <div className="flex flex-col justify-start items-start gap-1">
                <label
                  htmlFor="created_date"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Registered Date
                </label>
                <input
                  id="created_date"
                  required
                  type="text"
                  name="created_date"
                  value={formData.created_date}
                  className={`w-[300px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-1">
                <label
                  htmlFor="status"
                  className="text-[#9CA3AF] text-sm font-normal"
                >
                  Status
                </label>
                <input
                  id="status"
                  required
                  type="text"
                  name="status"
                  value={formData.status}
                  className={`w-[300px] outline-[#1B216A] pl-2.5 h-[40px] border-[1px] rounded-md border-[#9CA3AF]`}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default UserMain;
