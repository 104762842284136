import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Loader from "../../../../../components/common/Loader";
// import { fetchUsers } from "../../../visitorsApi/VisitorsApi";
import { ToastContainer } from "react-toastify";
// import { notify } from "../../../../../utils/Notify";
// import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Stack,
  TextField,
} from "@mui/material";
import { PiExport } from "react-icons/pi";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { notify } from "../../../../../utils/Notify";
import {
  deleteFileHistoryApi,
  getUploadFilesApi,
} from "../uploadsApi/uploadsApi";
import CoustomModal from "../../../../../components/Model/Model";
import { MdOutlineFileUpload } from "react-icons/md";
import { MdCancelScheduleSend } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const UploadsTable = () => {
  const navigation = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);
  const [page, setPage] = React.useState(0);
  const [fileId, setFileId] = React.useState();
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(6);
  const [loading, setLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] }); // For capturing filter state
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    { field: "sn", headerName: "SN", flex: 1 }, // Flexible width for serial number
    { field: "name", headerName: "File Name", flex: 2 }, // File Name column takes more space
    { field: "path", headerName: "File Path", flex: 2 }, // File Path with more space
    { field: "description", headerName: "Description", flex: 3 }, // Description takes more space
    { field: "created_date", headerName: "Registered Date", flex: 1.5 }, // Registered Date column
    {
      field: "",
      headerName: "Action",
      flex: 1, // Flexible width for the Action column
      renderCell: (params) => (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <RiDeleteBin5Line
              className="text-xl text-red-500 cursor-pointer"
              onClick={() => handleDeleteClick(params.row)}
            />
          </Box>
        </>
      ),
    },
  ];

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
    // setFileId(params._id);
  };

  const handleModelClose = () => {
    setOpenDeleteModel(false);
    setFileId("");
  };

  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    search: "",
    searchStartDate: "",
    searchEndDate: "",
    fields: ["name", "description"],
    sortBy: ["name", "created_at"],
    sortOrder: ["asc", "desc"],
  });

  const [usersData, setUsersData] = useState([]);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDateChange = (name, newValue) => {
    const formattedDate =
      newValue && moment(newValue).isValid()
        ? moment(newValue).format("DD-MM-YYYY")
        : "";
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedDate,
    }));
  };

  // Function to handle pagination change
  const handlePaginationChange = (paginationModel) => {
    setFormData((prev) => ({
      ...prev,
      page: paginationModel.page + 1, // MUI page is 0-indexed, so add 1
      size: paginationModel.pageSize,
    }));
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  // Function to handle sorting change
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setFormData((prev) => ({
        ...prev,
        sortBy: sortModel.map((sort) => sort.field), // Update sortBy fields
        sortOrder: sortModel.map((sort) => sort.sort), // Update sortOrder (asc/desc)
      }));
    }
  };

  const handleGetFilesList = async () => {
    handleOpenLoader();
    setLoading(true);
    try {
      const result = await getUploadFilesApi(formData);

      if (result.data.success === true) {
        handleCloseLoader();
        const rowsWithSN = result.data.files.map((row, index) => ({
          ...row,
          sn: index + 1, // Add serial number starting from 1
          created_date: row?.created_at?.date ?? "",
        }));
        setUsersData(rowsWithSN);
        setRowCount(result.data.totalCount);
        // setPageSize(result.data.totalPages);
        // setPage(result.data.currentPage);
        setLoading(false);
      } else if (result.data.success === false) {
        handleCloseLoader();
        notify(result.data.message[0], "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteFileHistory = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteFileHistoryApi(data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handleGetFilesList();
    setOpenDeleteModel(false);
  };

  useEffect(() => {
    if (formData.search.length === 0 || formData.search.length >= 4)
      handleGetFilesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.search, formData.searchDate]);

  useEffect(() => {
    handleGetFilesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.sortOrder, formData.page, formData.size]);

  useEffect(() => {
    handleGetFilesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formData.searchStartDate && formData.searchEndDate) {
      handleGetFilesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.searchStartDate, formData.searchEndDate]);

  return (
    <>
      <div className="flex justify-start items-center mb-2.5 py-5">
        <ToastContainer />
        <Loader open={openLoader} handleClose={handleCloseLoader} />
        <div className="flex justify-between items-center w-full">
          <div className="flex">
            <div className="flex justify-start items-start flex-col">
              <label
                htmlFor="category"
                className="text-[#0B3CAB] text-sm font-bold font-sans mb-2.5"
              >
                Search
              </label>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 200,
                  height: 40,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  id="category"
                  name="search"
                  inputProps={{ "aria-label": "search" }}
                  value={formData.search}
                  onChange={handleInput}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <GoSearch className="w-[18px] h-[18px]" />
                </IconButton>
              </Paper>
            </div>
            <div className="flex justify-end items-end ml-5">

              <DatePicker
                sx={{
                  p: "2px 4px",
                  width: 200,
                  "& .MuiInputBase-root": {
                    height: "40px", // Adjust the height of the input field
                  },
                }}
                label="Start Date"
                name="searchStartDate"
                onChange={(newValue) =>
                  handleDateChange("searchStartDate", newValue)
                }
                renderInput={(params) => <TextField {...params} />}
                format="DD-MM-YYYY"
              />
              <DatePicker
                sx={{
                  p: "2px 4px",
                  width: 200,
                  "& .MuiInputBase-root": {
                    height: "40px", // Adjust the height of the input field
                  },
                }}
                label="End Date"
                name="searchEndDate"
                onChange={(newValue) =>
                  handleDateChange("searchEndDate", newValue)
                }
                renderInput={(params) => <TextField {...params} />}
                format="DD-MM-YYYY"
              />
            </div>
            {selectedRows.length > 0 && (
              <div className="flex justify-end items-end ml-5">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 40,
                    height: 40,
                    backgroundColor: "#E5F1F9",
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => handleDeleteClick()}
                  >
                    <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
                  </IconButton>
                </Paper>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center gap-2">
            {/* <button className="text-white px-5 bg-gradient-2 h-8 rounded-md flex justify-center items-center gap-1">
              <PiExport className="rotate-90" /> Export
            </button> */}
            <button
              className="text-white px-5 bg-gradient-2 h-8 rounded-md flex justify-between items-center"
              onClick={() =>
                navigation("/finderscope/v1/admin/data-management/upload")
              }
            >
              <MdOutlineFileUpload className="w-5 h-5 mr-2" /> Upload
            </button>
          </div>
        </div>
      </div>
      <div className="h-[25rem] overflow-y-auto overflow-x-auto">
        <Paper sx={{ width: "100%", maxWidth: "100%", height: "100%" }}>
          <DataGrid
            rows={usersData}
            columns={columns}
            getRowId={(row) => row._id}
            // page={page}
            // pageSize={pageSize}
            paginationMode="server"
            rowCount={rowCount}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={handlePaginationChange} // Pagination event
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={selectedRows}
            onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
            pageSizeOptions={[5, 10, 25, 50, 100]}
            filterModel={filterModel} // Attach the filter model to DataGrid
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            } // Update filter model on filter change
            disableColumnMenu
            sortingMode="server" // Server-side sorting
            onSortModelChange={handleSortChange} // Sorting event
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
      <CoustomModal
        title={"Delete File History"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteFileHistory}
      >
        <p className=" text-red-800 my-10 ">
          Do you want to delete this file history
        </p>
      </CoustomModal>
    </>
  );
};

export default UploadsTable;
