import React from "react";
import ProductTable from "./ProductTable";

const ProductMain = () => {
  return (
    <div className="w-[100%]">
      <div className="bg-white px-10 py-2.5 my-2.5">
        <h2 className="text-[#0B3CAB] text-2xl font-bold">Products</h2>
      </div>
      <div className="bg-white px-10 py-2.5">
        <ProductTable />
      </div>
    </div>
  );
};

export default ProductMain;
