import axios from "axios";
import { getDataAPI, postDataAPI } from "../../../../../utils/fetchData";
import { baseUrl } from "../../../../../config/BaseUrl";

export const getCategoriesApi = async (keyword) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await getDataAPI(`categories?keyword=${keyword??''}`,token)
    return result
};

export const getCategoryApi = async (id) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await getDataAPI(`categories/${id}`,token)
    return result
};

export const getCategoryProductApi = async (id, data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await postDataAPI(`products/list/${id}`, data, token)
    return result
};

export const deleteProductDataApi = async (data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const res = await axios.delete(`${baseUrl}/api/products/multidelete`, {
        headers: { Authorization: token},
        data: data
    })
    return res;
};