import React from "react";
import UploadsTable from "./UploadsTable";

const UploadsMain = () => {
  return (
    <div className="w-[100%]">
      <div className="bg-white px-10 py-2.5 my-2.5 rounded">
        <h2 className="text-[#0B3CAB] text-2xl font-bold">Upload History</h2>
      </div>
      <div className="bg-white px-10 py-2.5  mr-4 rounded">
        <UploadsTable />
      </div>
    </div>
  );
};

export default UploadsMain;
