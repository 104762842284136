import { baseUrl } from "../../../../../config/BaseUrl";

export const resetPassword = (formData) => {
  return fetch(`${baseUrl}/api/admin/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};

export const verifyTokenId = (token) => {
  return fetch(`${baseUrl}/api/admin/verify/${token}`, {
    method: "GET",
  });
};
