import axios from "axios";
import { baseUrl } from "../../../../config/BaseUrl";
import { getDataAPI } from "../../../../utils/fetchData";

export const fetchUsers = (formData) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage

  return fetch(`${baseUrl}/api/admin/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`, // Add the token to the Authorization header
    },
    body: JSON.stringify(formData),
  });
};

export const fetchUserbyId = (id) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage

  return fetch(`${baseUrl}/api/admin/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`, // Add the token to the Authorization header
    },
  });
};

export const userStatusChangeApi = async (id) => {
  const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
  const result = await getDataAPI(`admin/users/toggle-status/${id}`, token);
  return result;
};

export const deleteUserApi = async (data) => {
  const token = localStorage.getItem("access");
  const res = await axios.delete(`${baseUrl}/api/admin/multidelete`, {
    headers: { Authorization: token },
    data: data,
  });
  return res;
};
