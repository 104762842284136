import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Loader from "../../../../../components/common/Loader";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../../utils/Notify";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { PiExport } from "react-icons/pi";
import { FaRegEdit } from "react-icons/fa";
import {
  categoriesStatusChangeApi,
  createCategorieslistApi,
  deleteCategorieslistApi,
  editCategorieslistApi,
  getCategorieslistApi,
} from "../categoriesApi/categoriesApi";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CoustomModal from "../../../../../components/Model/Model";
import MultiSelectValue from "../../../../../components/Inputs/MultiSelectValue";
import { IoAdd } from "react-icons/io5";
import { MdCancelScheduleSend } from "react-icons/md";
import { IoMdSend } from "react-icons/io";

const CategoriesTable = ({ modelOpen, handleCreateCategory }) => {
  const navigation = useNavigate();
  const [categoriesName, setCategoriesName] = useState("");
  const [categoriesDescription, setCategoriesDescription] = useState("");
  const [categoriesId, setCategoriesId] = useState("");
  const [feildValue, setFeildValue] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState();
  const [loading, setLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] }); // For capturing filter state
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    { field: "sn", headerName: "SN", flex: 1 }, // Auto width
    { field: "name", headerName: "Category Name", flex: 1 }, // Auto width
    { field: "created_by", headerName: "Created by", flex: 1 }, // Auto width
    { field: "created_date", headerName: "Created  Date", flex: 1 }, // Auto width
    {
      field: "status",
      headerName: "Status",
      flex: 1, // Adjust based on the content
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          className="h-full w-full flex justify-start items-center px-3 py-1"
        >
          <FormControlLabel
            sx={{ display: "flex", alignItems: "center" }}
            control={
              <Switch
                checked={params.row.status}
                onChange={() => handleToggelButton(params.row._id)}
                name="status"
                sx={{ color: "green" }}
              />
            }
            label={params.row.status ? "Active" : "Inactive"}
            labelPlacement="end"
          />
        </Box>
      ),
    },
    {
      field: "",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <FaRegEdit
              className="text-xl text-[#1B216A] cursor-pointer"
              onClick={() => handleEditClick(params.row)}
            />
            {/* </IconButton> */}
            {/* <IconButton
            color="secondary"
            aria-label="delete"
            // onClick={() => handleDeleteClick(params.row)}
          > */}
            <RiDeleteBin5Line
              className="text-xl text-red-500 cursor-pointer"
              onClick={() => handleDeleteClick(params.row)}
            />
            {/* </IconButton> */}
          </Box>
        </>
      ),
    },
  ];

  const handleEditClick = (params) => {
    const fieldValue = params.productFields.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    setOpenModel(true);
    setCategoriesName(params.name);
    setCategoriesDescription(params.description);
    setCategoriesId(params._id);
    setFeildValue(fieldValue);
  };

  const handleModelClose = () => {
    setOpenModel(false);
    setOpenDeleteModel(false);
    setCategoriesName("");
    setCategoriesDescription("");
    setCategoriesId("");
    setFeildValue([]);
  };

  const handleToggelButton = async (id) => {
    await categoriesStatusChangeApi(id);
    handleGetCategoriesList();
  };

  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    search: "",
    fields: ["name", "description"],
    sortBy: ["name", "created_at"],
    sortOrder: ["asc", "desc"],
  });

  const [usersData, setUsersData] = useState([]);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
  };

  // Function to handle pagination change
  const handlePaginationChange = (paginationModel) => {
    setFormData((prev) => ({
      ...prev,
      page: paginationModel.page + 1, // MUI page is 0-indexed, so add 1
      size: paginationModel.pageSize,
    }));
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  // Function to handle sorting change
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setFormData((prev) => ({
        ...prev,
        sortBy: sortModel.map((sort) => sort.field), // Update sortBy fields
        sortOrder: sortModel.map((sort) => sort.sort), // Update sortOrder (asc/desc)
      }));
    }
  };

  const handleGetCategoriesList = async () => {
    handleOpenLoader();
    setLoading(true);
    try {
      const result = await getCategorieslistApi(formData);

      if (result.data.success === true) {
        handleCloseLoader();
        const rowsWithSN = result.data.categories.map((row, index) => ({
          ...row,
          sn: index + 1, // Add serial number starting from 1
          created_date: row?.created_at?.date ?? "",
        }));
        setUsersData(rowsWithSN);
        setRowCount(result.data.totalCount);
        // setPageSize(result.data.totalPages);
        // setPage(result.data.currentPage);
        setLoading(false);
      } else if (result.data.success === false) {
        handleCloseLoader();
        notify(result.data.message[0], "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCategories = async () => {
    if (categoriesName !== "" && categoriesDescription !== "") {
      const fieldValueArr = feildValue.map((item) => item.value);
      const data = {
        name: categoriesName,
        description: categoriesDescription,
        productFields: fieldValueArr,
        parent_id: null,
      };
      let res;
      if (categoriesId) {
        res = await editCategorieslistApi(categoriesId, data);
        setCategoriesId("");
        setOpenModel(false);
      } else {
        res = await createCategorieslistApi(data);
        handleCreateCategory();
      }
      if (res.data.success === true) {
        notify(res.data.message[0], "success");
      } else if (res.data.success === false) {
        notify(res.data.message[0], "error");
      }
      handleGetCategoriesList();
    } else {
      notify("Please fill all the fields", "error");
    }
  };

  const handleDeleteCategories = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteCategorieslistApi(data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handleGetCategoriesList();
    setSelectedRows([]);
    setOpenDeleteModel(false);
  };

  // const handleExportCategoriesList = async () => {
  //   try {
  //     const result = await getCategorieslistApi("csv");

  //     console.log(">>>>>>>>>>>>>>>result_csv", result.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (formData.search.length === 0 || formData.search.length >= 4)
      handleGetCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.search]);

  useEffect(() => {
    handleGetCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.sortOrder, formData.page, formData.size]);

  useEffect(() => {
    setCategoriesName("");
    setCategoriesDescription("");
    setFeildValue([]);
  }, [modelOpen]);

  return (
    <>
      <div className="flex justify-start items-center mb-2.5 py-5 ">
        <ToastContainer />
        <Loader open={openLoader} handleClose={handleCloseLoader} />
        <div className="flex justify-between items-center w-full">
          <div className="flex">
            <div className="flex justify-start items-start flex-col">
              <label
                htmlFor="category"
                className="text-[#0B3CAB] text-sm font-bold font-sans mb-2.5"
              >
                Category
              </label>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 200,
                  height: 40,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Categories"
                  id="category"
                  name="search"
                  inputProps={{ "aria-label": "search categories" }}
                  value={formData.search}
                  onChange={handleInput}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <GoSearch className="w-[18px] h-[18px]" />
                </IconButton>
              </Paper>
            </div>
            {selectedRows.length > 0 && (
              <div className="flex justify-end items-end ml-5">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 40,
                    height: 40,
                    backgroundColor: "#E5F1F9",
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => handleDeleteClick()}
                  >
                    <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
                  </IconButton>
                </Paper>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center gap-3">
            {/* <button
              className="text-white px-5 bg-gradient-2 h-8 rounded-md flex justify-center items-center gap-1"
              onClick={handleExportCategoriesList}
            >
              <PiExport className="rotate-90" /> Export
            </button> */}
            <button
              className="text-white px-5 bg-gradient-2 h-8 rounded-md flex justify-between items-center"
              onClick={handleCreateCategory}
            >
              <IoAdd className="w-5 h-5 mr-2" /> Add Category
            </button>
          </div>
        </div>
      </div>
      <div className="h-[25rem]">
        <Paper sx={{ width: "auto", height: "100%" }}>
          <DataGrid
            rows={usersData}
            columns={columns}
            getRowId={(row) => row._id}
            // page={page}
            // pageSize={pageSize}
            paginationMode="server"
            rowCount={rowCount}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPaginationModelChange={handlePaginationChange} // Pagination event
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={selectedRows}
            onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
            pageSizeOptions={[5, 10, 25, 50, 100]}
            filterModel={filterModel} // Attach the filter model to DataGrid
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            } // Update filter model on filter change
            disableColumnMenu
            sortingMode="server" // Server-side sorting
            onSortModelChange={handleSortChange} // Sorting event
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
      <CoustomModal
        title={categoriesId ? "Update Category" : "Create Category"}
        open={openModel || modelOpen}
        handleClose={modelOpen ? handleCreateCategory : handleModelClose}
        handleSubmit={handleAddCategories}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "10px",
            }}
          >
            <TextField
              id="categories-name"
              label="Name"
              value={categoriesName}
              onChange={(event) => {
                setCategoriesName(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "10px",
            }}
          >
            <TextField
              id="categories-description"
              label="Description"
              value={categoriesDescription}
              onChange={(event) => {
                setCategoriesDescription(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "10px",
            }}
          >
            <lable className={`mb-2 mt-3 font-semibold`}>Product Fields</lable>
            <MultiSelectValue value={feildValue} setValue={setFeildValue} />
          </Grid>
        </Grid>
      </CoustomModal>
      <CoustomModal
        title={"Delete Category"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteCategories}
      >
        <p className=" text-red-800 my-10 ">Do you want to delete category</p>
      </CoustomModal>
    </>
  );
};

export default CategoriesTable;
