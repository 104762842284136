import React, { useEffect } from "react";
import CategoriesMain from "./components/CategoriesMain";
import Layout from "../../../../components/layout/Layout";
import { useHandleValidateRefresh } from "../../../../constants/RefreshValidate";
import Validate from "../../../../constants/Validate";
const ViewCategories = () => {
  const isLoggedIn = !!localStorage.getItem("access");
  const handleValidateRefresh = useHandleValidateRefresh();
  useEffect(() => {
    handleValidateRefresh();
  }, []);
  return isLoggedIn ? (
    <Layout>
      <CategoriesMain />
    </Layout>
  ) : (
    <Validate />
  );
};

export default ViewCategories;
