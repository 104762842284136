import axios from "axios";
import { deleteDataAPI, getDataAPI, postDataAPI, putDataAPI } from "../../../../../utils/fetchData";
import { baseUrl } from "../../../../../config/BaseUrl";

export const getCategoriesApi = async (keyword) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await getDataAPI(`categories?keyword=${keyword??''}`,token)
    return result;
};

export const downloadCsvOrExcelApi = async (format) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const headers = {'Content-Type': 'text/csv'};
    const result = await getDataAPI(`files/export?format=${format}`,token, headers)
    return result;
};

export const uploadCsvOrExcelApi = async (category, formData) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await postDataAPI(`files/upload/scan/${category}`, formData, token)
    return result;
};

export const scanCsvOrExcelApi = async (category, limit, page, sortBy, sortOrder) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await getDataAPI(`files/upload/product/match/${category}?page=${page??'1'}&limit=${limit??'10'}&sortBy=${sortBy??''}&sortOrder=${sortOrder??''}`, token)
    return result;
};

export const uploadNewFileApi = async (category, data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await putDataAPI(`files/upload/product/update/${category}`, data, token)
    return result;
};

export const getUploadFilesApi = async (data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await postDataAPI(`files/list`, data, token);
    return result;
};

export const selectCheckboxApi = async (data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await putDataAPI(`files/upload/product/check`, data, token);
    return result;
};

// export const deleteFileHistoryApi = async (id) => {
//     const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
//     const result = await deleteDataAPI(`files/${id}`, token)
//     return result;
// };

export const deleteFileHistoryApi = async (data) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const res = await axios.delete(`${baseUrl}/api/files/multidelete`, {
        headers: { Authorization: token},
        data: data
    })
    return res;
};

export const getErrorLogsApi = async (formData) => {
    const token = localStorage.getItem("access"); // Retrieve the access token from localStorage
    const result = await postDataAPI(`files/upload/product/log/error`, formData, token)
    return result;
};