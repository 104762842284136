import { baseUrl } from "../../../../../config/BaseUrl";

export const forgotPassword = (formData) => {
  return fetch(`${baseUrl}/api/admin/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};
