import React from "react";
import AllChatHistoryTable from "./AllChatHistoryTable";

const AllChatHistoryMain = () => {
  const [openModel, setOpenModel] = React.useState(false);

  const handleCreateCategory = () => {
    console.log("create category");
    setOpenModel(!openModel);
  };
  return (
    <div className="w-[100%] ">
      <div className="bg-white px-10 py-2.5 my-2.5 rounded">
        <h2 className="text-[#0B3CAB] text-2xl font-bold">All Chat History</h2>
      </div>
      <div className="bg-white px-10 py-2.5  mr-4 rounded">
        <AllChatHistoryTable
          modelOpen={openModel}
          handleCreateCategory={handleCreateCategory}
        />
      </div>
    </div>
  );
};

export default AllChatHistoryMain;
