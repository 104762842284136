import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Loader from "../../../../../../components/common/Loader";
import {
  deleteUserApi,
  fetchUsers,
  userStatusChangeApi,
} from "../../../visitorsApi/VisitorsApi";
import { ToastContainer } from "react-toastify";
import { notify } from "../../../../../../utils/Notify";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  FormControlLabel,
  IconButton,
  InputBase,
  Switch,
  TextField,
} from "@mui/material";
import { GoSearch } from "react-icons/go";
import CoustomModal from "../../../../../../components/Model/Model";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const VisitorsTable = () => {
  const navigation = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);

  // Define the functions
  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);
  const [page, setPage] = React.useState(0);
  // add by khailendra
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] }); // For capturing filter state

  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
    searchUsers: "",
    searchStartDate: "",
    searchEndDate: "",
    fields: ["name", "email"],
    sortBy: ["name", "created_at"],
    sortOrder: ["asc", "desc"],
  });

  const [usersData, setUsersData] = useState([]);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (name, newValue) => {
    const formattedDate = newValue && moment(newValue).isValid() 
    ? moment(newValue).format('DD-MM-YYYY') 
    : '';
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedDate,
    }));    
  };

  const handleSelectionChange = async (newSelection) => {
    setSelectedRows(newSelection); // Update state with selected row IDs
  };

  const handleDeleteClick = (params) => {
    setOpenDeleteModel(true);
    if (params && params?._id) {
      setSelectedRows([params._id]);
    }
    // setFileId(params._id);
  };

  const handleModelClose = () => {
    setOpenDeleteModel(false);
  };

  // Function to handle pagination change
  const handlePaginationChange = (paginationModel) => {
    setFormData((prev) => ({
      ...prev,
      page: paginationModel.page + 1, // MUI page is 0-indexed, so add 1
      size: paginationModel.pageSize,
    }));
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  // Function to handle sorting change
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setFormData((prev) => ({
        ...prev,
        sortBy: sortModel.map((sort) => sort.field), // Update sortBy fields
        sortOrder: sortModel.map((sort) => sort.sort), // Update sortOrder (asc/desc)
      }));
    }
  };

  const handleGetUsers = () => {
    handleOpenLoader();
    setLoading(true);
    fetchUsers(formData)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          handleCloseLoader();
          const rowsWithSN = data.users.map((row, index) => ({
            ...row,
            sn: index + 1, // Add serial number starting from 1
          }));
          setUsersData(rowsWithSN);
          setRowCount(data.totalCount);
          // setPageSize(data.totalPages);
          // setPage(data.currentPage);
          setLoading(false);
        } else if (data.success === false) {
          handleCloseLoader();
          notify(data.message[0], "error");
          setLoading(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteUser = async () => {
    const data = {
      ids: [...selectedRows],
    };
    const res = await deleteUserApi(data);
    if (res.data.success === true) {
      notify(res.data.message, "success");
    } else if (res.data.success === false) {
      notify(res.data.message, "error");
    }
    handleGetUsers();
    setOpenDeleteModel(false);
  };

  const handleToggelButton = async (id) => {
    await userStatusChangeApi(id);
    handleGetUsers();
  };

  useEffect(() => {
    if (formData.searchUsers.length === 0 || formData.searchUsers.length >= 4)
      handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.searchUsers]);
  useEffect(() => {
    if (formData.searchStartDate && formData.searchEndDate) {
      handleGetUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.searchStartDate, formData.searchEndDate]);
  
  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.sortOrder, formData.page, formData.size]);

  const columns = [
    { field: "sn", headerName: "SN", flex: 1 }, // flex: 1 will take up remaining space
    { field: "name", headerName: "Full Name", flex: 2 }, // More flexible space
    { field: "email", headerName: "Email", flex: 2 }, // Email column takes up more space
    { field: "created_date", headerName: "Registered Date", flex: 2 },
    {
      field: "status",
      headerName: "Status",
      flex: 1, // Adjust based on the content
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          className="h-full w-full flex justify-start items-center px-3 py-1"
        >
          {/* <button
            className={`${
              params.row.status
                ? "text-[#3fd73f]  border-[2px] font-semibold border-[#3fd73f]"
                : "text-[#FF0000] font-semibold border-[2px] border-[#FF0000]"
            } h-10 px-2.5 py-1 bg-transparent rounded-md flex justify-center items-center content-center text-center`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </button> */}
          <FormControlLabel
            sx={{ display: "flex", alignItems: "center" }}
            control={
              <Switch
                checked={params.row.status}
                onChange={() => handleToggelButton(params.row._id)}
                name="status"
                sx={{ color: "green" }}
              />
            }
            label={params.row.status ? "Active" : "Inactive"}
            labelPlacement="end"
          />
        </Box>
      ),
    },
    {
      field: "",
      headerName: "Action",
      flex: 1, // Take minimal space
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <MdOutlineRemoveRedEye
            className="text-xl text-[#1B216A] cursor-pointer"
            onClick={() =>
              navigation(
                `/finderscope/v1/admin/visitors/user/${params.row._id}`
              )
            }
          />
          <RiDeleteBin5Line
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDeleteClick(params.row)}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-start mb-2.5 py-5">
        <ToastContainer />
        <Loader open={openLoader} handleClose={handleCloseLoader} />
        {/* <div className="flex justify-start items-start gap-1 flex-col">
          <label htmlFor="user" className="text-[#0B3CAB] text-xs font-bold">
            User
          </label>
          <input
            id="user"
            type="text"
            value={formData.searchUsers}
            name="searchUsers"
            className="w-[150px] border-[2px] border-gray-300 p-1.5 outline-none"
            onChange={handleInput}
          />
        </div> */}
        <div className="flex justify-start items-start flex-col">
          <div
            htmlFor="category"
            className="text-[#0B3CAB] text-sm font-bold font-sans mb-1"
          >
            User
          </div>
          <div className="flex gap-3">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 200,
              height: 40,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Users"
              id="user"
              name="searchUsers"
              inputProps={{ "aria-label": "search users" }}
              value={formData.searchUsers}
              onChange={handleInput}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <GoSearch className="w-[18px] h-[18px]" />
            </IconButton>
          </Paper>
          {/* <DesktopDatePicker
            sx={{
              p: "2px 4px",
              width: 200,
              "& .MuiInputBase-root": {
                height: "40px", // Adjust the height of the input field
              },            }}
            slotProps={{
              field: { clearable: true, onClear: () => true },
            }}
          /> */}
          <DatePicker
            sx={{
              p: "2px 4px",
              width: 200,
              "& .MuiInputBase-root": {
                height: "40px", // Adjust the height of the input field
              },            }}
            label="Start Date"
            name="searchStartDate"
            onChange={(newValue) => handleDateChange("searchStartDate", newValue)}
            renderInput={(params) => <TextField {...params} />}
            format="DD-MM-YYYY"
          />
          <DatePicker
            sx={{
              p: "2px 4px",
              width: 200,
              "& .MuiInputBase-root": {
                height: "40px", // Adjust the height of the input field
              },            }}
            label="End Date"
            name="searchEndDate"
            onChange={(newValue) => handleDateChange("searchEndDate", newValue)}
            renderInput={(params) => <TextField {...params} />}
            format="DD-MM-YYYY"
          />
          {selectedRows.length > 0 && (
              <div className="flex justify-end items-end ml-2">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 40,
                    height: 40,
                    backgroundColor: "#E5F1F9",
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => handleDeleteClick()}
                  >
                    <RiDeleteBin6Line className="w-[18px] h-[18px] text-[#000000] " />
                  </IconButton>
                </Paper>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-start items-start gap-1 flex-col">
          {/* <label
            htmlFor="date"
            className="text-[#0B3CAB] text-xs font-bold outline-none"
          >
            Date
          </label>
          <input
            id="date"
            type="date"
            name="searchDate"
            onChange={handleInput}
            value={formData.searchDate}
            className="w-[150px] border-[2px] border-gray-300 p-1.5"
          /> */}
          {/* <DatePicker
            sx={{
              p: "2px 4px",
              width: 200,
              height: "auto", // Set height to auto to allow flexibility
              "& .MuiInputBase-root": {
                height: "40px", // Adjust the height of the input field
              },            }}
            label="Date"
            name="searchDate"
            // onChange={handleInput}
            // value={formData.searchDate}
          /> */}
        </div>
      </div>
      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  SN
                </TableCell>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  Full Name
                </TableCell>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  Registered Date
                </TableCell>
                <TableCell style={{ fontWeight: "600", background: "#F1F3F7" }}>
                  Status
                </TableCell>
                <TableCell
                  colSpan={2}
                  style={{ fontWeight: "600", background: "#F1F3F7" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={user._id}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.created_date}</TableCell>
                      <TableCell>
                        <button
                          className={
                            user.status
                              ? "text-[#3fd73f] border-[2px] font-semibold border-[#3fd73f] px-2.5 py-1 bg-transparent rounded-md"
                              : "text-[#FF0000]  font-semibold border-[2px] border-[#FF0000] px-2.5 py-1 bg-transparent rounded-md"
                          }
                        >
                          {user.status ? "Active" : "Inactive"}
                        </button>
                      </TableCell>
                      <TableCell>
                        <MdOutlineRemoveRedEye
                          className="text-xl text-[#1B216A] cursor-pointer"
                          onClick={() =>
                            navigation(
                              `/finderscope/v1/admin/visitors/user/${user._id}`
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <RiDeleteBin5Line className="text-xl text-red-500 cursor-pointer" />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {usersData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={usersData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <h1 className="text-center my-10 text-2xl font-semibold">
            No Data Found
          </h1>
        )}
      </Paper> */}
      <div>
        <Paper sx={{ width: "100%", height: "25.5rem" }}>
          <DataGrid
            rows={usersData}
            columns={columns}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            // page={page}
            // pageSize={pageSize}
            paginationMode="server"
            paginationModel={{ page: page, pageSize: pageSize }}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPaginationModelChange={handlePaginationChange} // Pagination event
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={selectedRows}
            onRowSelectionModelChange={handleSelectionChange} // Handle checkbox selection
            pageSizeOptions={[5, 10, 25, 50, 100]}
            filterModel={filterModel} // Attach the filter model to DataGrid
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            } // Update filter model on filter change
            disableColumnMenu
            sortingMode="server" // Server-side sorting
            onSortModelChange={handleSortChange} // Sorting event
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </Paper>
      </div>
      <CoustomModal
        title={"Delete User"}
        open={openDeleteModel}
        handleClose={handleModelClose}
        handleSubmit={handleDeleteUser}
      >
        <p className=" text-red-800 my-10 ">
          Do you want to delete this file history
        </p>
      </CoustomModal>
    </>
  );
};

export default VisitorsTable;
